import clientIcon from "../images/menu/clients.svg";
import firmadminIcon from "../images/menu/firmadmin.svg";
import firmemployeesIcon from "../images/menu/firmemployees.svg";
import accountantsIcon from "../images/menu/accountants.svg";
import adminIcon from "../images/menu/admin.svg";
import affiliateIcon from "../images/registration/logo/affiliate.svg";
import rocketIcon from "../images/registration/logo/rocket.svg";
import searchIcon from "../images/search.svg";

//workflow
import controlsIcon from "../images/pages/home/workflow/controls.svg";
import connectIcon from "../images/pages/home/workflow/connect.svg";
import dataExtractionIcon from "../images/pages/home/workflow/data-extraction.svg";
import metricsIcon from "../images/pages/home/workflow/metrics.svg";
import stopIcon from "../images/pages/home/workflow/stop.svg";
import presentationIcon from "../images/pages/home/workflow/presentation.svg";

export const mainMenuItems = [
  { name: "Owner", image: clientIcon, subMenuItem: "Client" },
  // { name: "Accountants", image: firmadminIcon, subMenuItem: "FinACE’ured" },
  // {
  //   name: "Admins",
  //   image: firmemployeesIcon,
  //   subMenuItem: "FinACE’ured",
  // },
  // {
  //   name: "Non-Admin Staff",
  //   image: accountantsIcon,
  //   subMenuItem: "FinACE’ured",
  // },
  // { name: "Super Admin", image: adminIcon, subMenuItem: "CRM" },
];

export const subMenuItemsClients = [
  {
    name: "Staffing",
    link: "/owner/staffing/staff",
  },
  { name: "Client", link: "/owner/client/users" },
  { name: "Appointment" },
  { name: "Consulting" },
  { name: "Therapy" },
  { name: "Program" },
  { name: "Accommodation" },
  { name: "Billing" },
  { name: "Membership" },
  { name: "Marketing" },
  { name: "Pharmacy" },
  { name: "Kitchen" },
  {
    name: "Organization",
    link: "/owner/organization/locations",
  },
];

export const subMenuItemsCPAAdmin = [
  { name: "FinACE’ured" },
  { name: "Employees" },
  { name: "Workflow Automation" },
  { name: "CRM" },
  { name: "Services" },
  { name: "Hire/Outsource" },
  { name: "Marketplace" },
  { name: "Company Profile" },
];

export const subMenuItemsCPAEmployees = [
  { name: "FinACE’ured" },
  { name: "Projects" },
  { name: "CRM" },
  { name: "Workflow Automation" },
  { name: "Support" },
];

export const subMenuItemsAccountants = [
  { name: "FinACE’ured" },
  { name: "Projects" },
  { name: "CRM" },
  { name: "Workflow Automation" },
  { name: "Services" },
  { name: "Accountant Profile" },
];

export const subMenuItemsAdmin = [
  { name: "CRM", link: "/admin/crm/users" },
  // { name: "Overview" },
  // { name: "Support" },
];

export const registrationItems = [
  {
    image: affiliateIcon,
    title: "Via An Affiliate",
    desc: "One of other many people who love Montage sent you here",
  },
  {
    image: rocketIcon,
    title: "I Am Pre-Registered",
    desc: "I am an existing client of a firm who on Montage",
  },
  {
    image: searchIcon,
    title: "I Got Here By Myself",
    desc: "Google, social media, etc",
  },
];

export const platformItems = [
  {
    name: "FinACE’ured",
    desc: "We enhance essential accounting operations by automating and safeguarding all processes, from handling financial data management to reporting to stakeholders, ensuring accuracy..",
    color: "#D6A86B",
  },
  {
    name: "Accountants.io",
    desc: "We enhance essential accounting operations by automating and safeguarding all processes, from handling financial data management to reporting to stakeholders, ensuring accuracy..",
    color: "#191F41 ",
  },
  {
    name: "Luca",
    desc: "We enhance essential accounting operations by automating and safeguarding all processes, from handling financial data management to reporting to stakeholders, ensuring accuracy..",
    color: "#59A2DD",
  },
];

export const workflowItems = [
  { image: controlsIcon, desc: "Bookkeeping" },
  {
    image: connectIcon,
    desc: "Year-end compliance",
  },
  {
    image: dataExtractionIcon,
    desc: "Reconciliations",
  },
  { image: metricsIcon, desc: "Software conversion & integration" },
  {
    image: stopIcon,
    desc: "Individual & Business Tax Filings/Planning",
  },
  {
    image: presentationIcon,
    desc: "Payroll Processing",
  },
];

export const academyItems = [
  { title: "Canada" },
  {
    title: "United States",
  },
  {
    title: "United Kingdom",
  },
  { title: "UAE" },
];

export const analyticsItems = [
  {
    title: "Flawless financial oversight",
    desc: "Enjoy 100% confidence in your numbers, know exactly what you team is working on, and protect your company from unplanned risk.",
  },
  {
    title: "Accurate, compliant reporting workflows",
    desc: "Use modern reporting methods for Rev Rec, Lease Accounting & more while meeting the global compliance requirements automatically.",
  },
  {
    title: "Smarter reporting and analytics",
    desc: "Enable the best business outcomes with automated, configurable reporting and unique insights into your financial data.",
  },
  {
    title: "Level up reporting.",
    desc: "Eliminate repetitive tasks and human error with automated data workflows, AI-enabled verification, and anomaly detection for a streamlined close.",
  },
];

export const staffGroupFormModules = [
  "Staffing",
  "Client",
  "Appointment",
  "Consulting",
  "Therapy",
  "Program",
  "Accommodation",
  "Billing",
  "Membership",
  "Marketing",
  "Pharmacy",
  "Kitchen",
];

export const staffGroupFormGroupType = ["Accountants", "Non Admin Staff", "Admins"];
