import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./layout.module.scss";
import { GlobalContext } from "../context/Globalcontext";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import { useClickAway } from "use-click-away";
import { useDetectClickOutside } from "react-detect-click-outside";
//assets
import logoWithName from "../assets/updatedlogo.svg";
import mongo from "../assets/images/logo.svg";
import { ReactComponent as Bookings } from "../assets/12312.svg";
import { ReactComponent as OverviewIcon } from "../assets/images/sidebar/logo/overview.svg";
import { ReactComponent as PatientIcon } from "../assets/images/sidebar/logo/patient.svg";
import { ReactComponent as OrganizationIcon } from "../assets/images/sidebar/logo/organization.svg";
import { ReactComponent as Kitchicon } from "../assets/images/sidebar/logo/kitch.svg";

import { ReactComponent as Programer } from "../assets/programer.svg";
import { ReactComponent as Theraplogo } from "../assets/therapy.svg";
import { ReactComponent as AccomadtionIcon } from "../assets/accomadtion.svg";
import { ReactComponent as PharamisIcon } from "../assets/pharamis.svg";
import { ReactComponent as SpcailIcon } from "../assets/images/icons/spcial.svg";
// import { ReactComponent as WalletscanIcon } from "../assets/images/sidebar/logo/walletscan.svg";
import shorupan from "../assets/sho.svg";
//menu icons
import bookkeepingIcon from "../assets/images/menu/bookkeeping/bookkeeping.svg";
import notificationIcon from "../assets/images/icons/notification.svg";
import profileIcon from "../assets/images/icons/profile.svg";
import { BsDot } from "react-icons/bs";
import { MdKeyboardArrowUp } from "react-icons/md";
import tempNotoficationImage from "../assets/images/pages/dashboard/tempnotificationimage.svg";
import headspaceIcon from "../assets/images/pages/dashboard/headspace.svg";
import rahaIcon from "../assets/images/pages/dashboard/raha.svg";
import { IoIosArrowBack } from "react-icons/io";
import { toastify } from "../assets/functions/toastify";
import { useNetworkRequest } from "../hooks/networkRequest";
import Consultation from "../pages/Consultation/Consultation";
import { filterDetails } from "../assets/functions";
import "./Custom.scss";
import {
  mainMenuItems,
  subMenuItemsAccountants,
  subMenuItemsAdmin,
  subMenuItemsCPAAdmin,
  subMenuItemsCPAEmployees,
  subMenuItemsClients,
} from "../assets/data/mapItems";
import montageLogo from "../assets/images/montage.svg";

const PostLogin = ({ child }) => {
  const navigate = useNavigate();
  const { roleType, organizationFilterType, patientRoleType } = useParams();
  const location = useLocation();
  const {
    email,
    userLoginHandler,
    setdropDowndownvalue,
    actionBtns,
    setActionBtns,
    globalSearchQuery,
    setGlobalSearchQuery,
    accessPages,
    seteditingToggle,
  } = useContext(GlobalContext);

  const [selectedSidebarItem, setSelectedSidebarItem] = useState("");
  const [dropdownActive, setDropdownActive] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    localStorage.getItem("selectedMenuItem") ||
      (location?.pathname?.includes("settings") && "Settings") ||
      "Owner"
  );
  const [selectedMenuItemSub, setSelectedMenuItemSub] = useState(
    localStorage.getItem("selectedMenuItemSub") || "Staffing"
  );
  const [selectedMenuItemHover, setSelectedMenuItemHover] = useState("");
  const [selectedMenuItemSubHover, setSelectedMenuItemSubHover] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [expandSidebar, setExpandSidebar] = useState(false);

  useEffect(() => {
    localStorage.setItem("drawershow", 0);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("drawershow") > 0) {
      setExpandSidebar(true);
    }
    //
  }, [localStorage?.getItem("drawershow")]);

  function logoutFunction() {
    userLoginHandler();
    localStorage.clear();
    navigate(`/`);
  }

  const dropdownMenuButtonRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const notificationRef = useRef(null);
  const notificationButtonRef = useRef(null);
  const [urllist, seturllist] = useState("");
  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target) &&
        dropdownMenuButtonRef.current &&
        !dropdownMenuButtonRef.current.contains(event.target)
      ) {
        setDropdownActive(false);
      }
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target) &&
        notificationButtonRef.current &&
        !notificationButtonRef.current.contains(event.target)
      ) {
        setOpenNotification(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setGlobalSearchQuery("");
  }, [location]);

  const clickRef = React.useRef("");
  useClickAway(clickRef, () => {
    setExpandSidebar(false);
  });

  useEffect(() => {
    tablefucntion();
  }, []);
  const [profiledata, setprofiledata] = useState("");
  useEffect(() => {
    seteditingToggle(false);
  }, [window?.location?.pathname]);
  const tablefucntion = () => {
    console.log("profiledata", profiledata);
    axios
      .get(`https://api.employment.group/api/checkuser/${email} `)
      .then((res) => {
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
        setprofiledata(res?.data?.email?.ProfilePic);
      });
  };

  const [hoverdata, sethoverdata] = useState("");
  useEffect(() => {
    seturllist(location?.pathname);
  }, []);

  const { id } = useParams();
  function renderSidebar() {
    switch (window?.location?.pathname) {
      case "/owner/therapies/therapySessions":
      case "/owner/kitchen/dietTemplates":
      case `/owner/kitchen/dietTemplates/${id}`:
      case "/owner/staffing/staff":
      case `/owner/staffing/staff/${id}`:
      case `/owner/staffing/staffgroups`:
      case `/owner/staffing/staffroles`:
      case `/owner/staffing/staffgroup/${id}s`:
      case `/owner/staffing/staffroles/${id}`:
      case `/owner/staffing/calendar/${id}`:
      case `/owner/staffing/staffgroups/${id}`:
      case `/owner/staffing/staffroles/${id}`:
      case `/owner/pharmacy/products/${id}`:

      case "/owner/staffing/calendar":
      case "/owner/client/users":
      case `/owner/client/users/${id}`:
      case "/owner/staffings/locations":
      case `/owner/staffing/locations/${id}`:
      case "/owner/organization/users":
      case "/owner/consultation/serviceTypes":
      case "/owner/consultation/dashboard":
      case "/owner/consultation/services":

      case `/owner/consultation/sessions/${id}`:
      case `/owner/consultation/serviceTypes/${id}`:
      case `/owner/consultation/services/${id}`:
      case `/owner/consultation/services/${id}`:
      // /owner/consultation/services/4cs38z6jlvf7z4l2
      case `/owner/staffing/accountants/${id}`:
      // /owner/organization/services
      case "/owner/bookings/dashboard":
      case "/owner/bookings/calendars":
      case "/owner/staffing/accountants":
      case "/owner/consultation/sessions":
      case "/owner/forms/genericforms":
      case "/owner/forms/patientProfileForms":
      case "/owner/forms/widgetForms":
      case "/owner/forms/consultationSummaryForms":

      case `/owner/forms/genericforms/${id}`:
      case `/owner/forms/patientProfileForms/${id}`:
      case `/owner/forms/widgetForms/${id}`:
      case `/owner/forms/consultationSummaryForms/${id}`:

      // case "/settings/owner":
      case "/owner/pharmacy/prescriptions":
      case `/owner/pharmacy/prescriptions/${id}`:
      case "/owner/pharmacy/Categories":
      case `/owner/pharmacy/Categories/${id}`:
      case "/owner/pharmacy/brands":
      case `/owner/pharmacy/brands/${id}`:
      case "/owner/pharmacy/products":
      case `/owner/accommodations/categories/${id}`:
      case "/owner/accommodations/rooms":
      case `/owner/accommodations/rooms/${id}`:
      case `/owner/accommodations/accommodations/${id}`:
      case "/owner/accommodations/categories":
      case "/owner/therapies/offerings":
      case `/owner/therapies/offerings/${id}`:
      case "/owner/packages":
      case `/owner/packages/${id}`:
      case "/owner/therapies/categories":
      case `/owner/therapies/categories/${id}`:
      case `/owner/kitchen/items/${id}`:
      case "/owner/kitchen/items":
      case "/owner/automation/pipelines":
      case "/owner/automation/stages":
      case "/owner/automation/steps":
      case "/owner/automation/automatedtasks":
      case "/owner/automation/manualtasks":
        return (
          <>
            <ReactTooltip id="Staffing" place="bottom" content="Staffing" />

            <ReactTooltip
              id="Client"
              place="bottom"
              variant="info"
              content="Client"
            />

            <ReactTooltip
              id="Organization"
              place="bottom"
              content="Organization"
            />
            <ReactTooltip id="Automation" place="bottom" content="Automation" />
            <ReactTooltip
              id="Bookings"
              place="bottom"
              variant="info"
              content="Bookings"
            />

            <ReactTooltip
              id="Consultation"
              place="bottom"
              content="Consultation"
            />
            <ReactTooltip
              id="pharmacy"
              place="bottom"
              variant="info"
              content="Pharmacy"
            />

            <ReactTooltip id="therapies" place="bottom" content="Therapies" />
            <ReactTooltip
              id="accommodations"
              place="bottom"
              variant="info"
              content="Accommodations"
            />

            <div
              style={{
                display:
                  accessPages?.title == "teamowner"
                    ? ""
                    : accessPages?.modules?.includes("Staffing")
                    ? ""
                    : "none",
              }}
              className={`${classNames.eachItem} ${
                urllist.includes("/owner/staffing")
                  ? classNames.eachItemSelected
                  : ""
              }`}
              data-tooltip-id="Staffing"
              onClick={() => {
                setSelectedSidebarItem("Staffing");
                //  navigate("/owner/staffing/staff");
                seturllist("/owner/staffing");
              }}
              onMouseEnter={() => {
                sethoverdata("Staffing");
              }}
              onMouseLeave={() => {
                sethoverdata("");
              }}
            >
              <OverviewIcon />

              {urllist.includes("/owner/staffing") && (
                <div className={classNames.title}>Staffing</div>
              )}

              {/* <div className={classNames.title}>Staffing</div> */}
            </div>
            <div
              style={{
                display:
                  accessPages?.title == "teamowner"
                    ? ""
                    : accessPages?.modules?.includes("Client")
                    ? ""
                    : "none",
              }}
              className={`${classNames.eachItem} ${
                urllist.includes("/owner/client")
                  ? classNames.eachItemSelected
                  : ""
              }`}
              data-tooltip-id="Client"
              onClick={() => {
                setSelectedSidebarItem("Client");

                // navigate("/owner/client/users");
                seturllist("/owner/client");
              }}
              onMouseEnter={() => {
                sethoverdata("Client");
              }}
              onMouseLeave={() => {
                sethoverdata("");
              }}
            >
              <PatientIcon />

              {urllist.includes("/owner/client") && (
                <div className={classNames.title}>Client</div>
              )}
            </div>
            {/* <div
              className={`${classNames.eachItem} ${urllist?.includes("/owner/organization")
                ? classNames.eachItemSelected
                : ""
                }`}
              data-tooltip-id="Organization"

              onClick={() => {
                setSelectedSidebarItem("Organization");
              
                seturllist("/owner/organization");
              }}

              onMouseEnter={() => {
                sethoverdata("Organization");

              }}
              onMouseLeave={() => {
                sethoverdata("");
              }}
            >
              <OrganizationIcon />

              {
                urllist.includes("/owner/organization")
                &&
                <div className={classNames.title}>Organization</div>
              }


            </div> */}
            <div
              style={{
                display:
                  accessPages?.title == "teamowner"
                    ? ""
                    : accessPages?.modules?.includes("Appointment")
                    ? ""
                    : "none",
              }}
              className={`${classNames.eachItem} ${
                urllist?.includes("/owner/bookings")
                  ? classNames.eachItemSelected
                  : ""
              }`}
              data-tooltip-id="Bookings"
              onClick={() => {
                setSelectedSidebarItem("Bookings");
                // navigate("/owner/bookings/dashboard");
                seturllist("/owner/bookings");
              }}
              onMouseEnter={() => {
                sethoverdata("Bookings");
              }}
              onMouseLeave={() => {
                sethoverdata("");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
              >
                <g>
                  <path d="M21.2781 15.5643V16.5813C21.4451 20.6027 27.1736 20.5996 27.339 16.5813V15.5679C25.4896 16.2201 23.1286 16.2166 21.2781 15.5643Z" />
                  <path d="M27.2785 13.5067C27.0394 12.4736 26.1123 11.701 25.0076 11.701H23.6094C22.5064 11.701 21.5804 12.4712 21.3396 13.5019C22.9383 14.3136 25.69 14.3189 27.2785 13.5067Z" />
                  <path d="M23.6095 9.76533H25.0077C25.4315 9.76533 25.8407 9.8283 26.2275 9.94391C26.3395 8.82212 25.436 7.82601 24.3076 7.83227C23.1784 7.82594 22.276 8.82257 22.3872 9.94462C22.7747 9.82855 23.1847 9.76533 23.6095 9.76533Z" />
                  <path d="M5.74243 16.5814C5.74243 18.2524 7.10185 19.6119 8.7729 19.6119C10.4439 19.6119 11.8034 18.2524 11.8034 16.5814V16.284L5.74243 15.2076V16.5814Z" />
                  <path d="M9.47194 11.7009H8.07374C7.05705 11.7009 6.19082 12.3553 5.8728 13.2649L11.8033 14.3181V14.0323C11.8033 12.7468 10.7575 11.7009 9.47194 11.7009Z" />
                  <path d="M24.2067 31.097H22.605L22.1929 28.1729H10.8054L10.3462 31.097H8.87772C7.59366 31.1482 7.59463 32.9819 8.87772 33.0326H24.2067C25.4908 32.9815 25.4898 31.1477 24.2067 31.097Z" />
                  <path d="M29.8573 0H3.16273C1.41878 0 0 1.41878 0 3.16273V23.1411C0 24.885 1.41878 26.3039 3.16273 26.3039H29.8573C31.6012 26.3039 33.02 24.8851 33.02 23.1411V3.16273C33.02 1.41878 31.6012 0 29.8573 0ZM31.0844 23.1411C31.0844 23.8178 30.5339 24.3683 29.8573 24.3683H29.6604C29.2937 22.7358 27.8143 21.5475 25.9925 21.5475H22.6245C20.885 21.5475 19.4183 22.7464 18.9919 24.3683H14.0885C13.6635 22.7466 12.1966 21.5475 10.4569 21.5475H7.08891C5.27218 21.5475 3.79592 22.7347 3.42281 24.3683H3.16273C2.48605 24.3683 1.93557 23.8178 1.93557 23.1411V22.7846C2.5518 21.4727 3.75218 20.4173 5.12353 19.945C4.30685 19.0596 3.80689 17.878 3.80689 16.5814V14.0323C3.80689 11.6795 5.72098 9.76536 8.0738 9.76536H9.47199C11.8248 9.76536 13.739 11.6795 13.739 14.0323V15.4268C13.7403 15.4545 13.7401 15.4824 13.739 15.5105V16.5814C13.739 17.8848 13.2335 19.0719 12.4092 19.9587C13.796 20.4695 14.9317 21.5084 15.5745 22.8319V1.93557H17.5101V22.8245C18.1542 21.5046 19.2882 20.4686 20.6724 19.9588C19.848 19.0719 19.3425 17.8848 19.3425 16.5814V14.0323C19.3425 12.8367 19.8375 11.755 20.6325 10.9795C19.7998 8.5353 21.7086 5.86105 24.3075 5.89679C26.903 5.86215 28.817 8.53014 27.9807 10.9757C28.7673 11.7411 29.2603 12.806 29.2734 13.9845C29.2735 13.9876 29.2735 13.9907 29.2736 13.9939C29.2738 14.0067 29.2746 14.0194 29.2746 14.0323V16.5814C29.2746 17.8761 28.7762 19.0561 27.9617 19.9411C29.3332 20.4197 30.444 21.3901 31.0844 22.6449V23.1411Z" />
                </g>
              </svg>

              {urllist.includes("/owner/bookings") && (
                <div className={classNames.title}>Bookings</div>
              )}
            </div>
            <div
              style={{
                display:
                  accessPages?.title == "teamowner"
                    ? ""
                    : accessPages?.modules?.includes("Consulting")
                    ? ""
                    : "none",
              }}
              className={`${classNames.eachItem} ${
                urllist?.includes("/owner/consultation/services")
                  ? classNames.eachItemSelected
                  : ""
              }`}
              data-tooltip-id="Consultation"
              onMouseEnter={() => {
                sethoverdata("Consultation");
              }}
              onMouseLeave={() => {
                sethoverdata("");
              }}
              onClick={() => {
                setSelectedSidebarItem("Consultation");

                seturllist("/owner/consultation");
              }}
            >
              <svg
                width="34"
                height="28"
                viewBox="0 0 34 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M23.858 20.8373C23.8839 20.8631 23.9084 20.8899 23.9334 20.9164C23.9464 20.9283 23.96 20.9392 23.9726 20.9518C25.0326 21.9125 26.4377 20.4787 25.4572 19.4236L25.2377 19.2041C25.2354 19.2018 25.2332 19.1996 25.231 19.1974L18.4049 12.3714L17.5605 13.2158C16.1941 14.6425 13.7398 14.6966 12.3064 13.3306C10.7033 11.9216 10.6698 9.24624 12.2313 7.79822L14.9514 5.15913L7.05459 0.343903C6.67064 0.109779 6.17639 0.168858 5.85842 0.486829L0.285361 6.05989C-0.017001 6.36225 -0.0868514 6.82651 0.113154 7.20452L3.46184 13.5328L4.38196 12.6127C5.90396 11.0951 8.31602 11.2839 9.68981 12.8998C10.2741 13.4842 10.5792 14.2431 10.6065 15.0102C11.4162 15.0763 12.2075 15.4175 12.8257 16.0356L13.113 16.3229C13.68 16.89 14.0031 17.6358 14.031 18.4333C17.0048 18.6539 18.3851 22.3605 16.2487 24.4792L15.208 25.52L17.1917 27.5037C17.4967 27.8086 17.9338 27.9332 18.3738 27.8242C19.2994 27.5869 19.5799 26.3895 18.9271 25.7683C18.486 25.3271 18.486 24.6119 18.9271 24.1707L18.9272 24.1707C19.3471 23.6706 20.0527 23.6691 20.5012 24.1457C21.0396 24.7144 21.9882 24.6504 22.4342 23.9536C22.7405 23.4818 22.656 22.8162 22.2605 22.4349C21.8194 21.9937 21.8193 21.2785 22.2605 20.8373C22.7016 20.3962 23.4169 20.3962 23.858 20.8373Z"></path>
                <path d="M3.82759 19.4784C4.17246 19.4784 4.49662 19.3441 4.74042 19.1003L7.80519 16.0355C7.80538 16.0353 7.80564 16.0351 7.8059 16.0349L8.09252 15.7483C8.43739 15.4033 8.43739 14.8422 8.09252 14.4973L7.80525 14.2101C7.32469 13.7092 6.46017 13.7093 5.97966 14.21L2.91489 17.2748C2.41156 17.7781 2.41162 18.597 2.91489 19.1004C3.15863 19.3441 3.48279 19.4784 3.82759 19.4784Z"></path>
                <path d="M28.2824 14.7194C29.8692 13.2363 31.6075 9.21631 32.8916 6.96324C33.1098 6.58038 33.0456 6.09898 32.732 5.78932L27.1545 0.281081C26.8376 -0.0319238 26.3487 -0.0901003 25.9671 0.139767L21.2154 3.00266C20.2795 3.42725 19.4095 3.96928 18.6751 4.69416L13.8033 9.4207C13.253 9.93165 13.1729 10.8536 13.6391 11.4518C14.1823 12.1996 15.3258 12.2777 15.9629 11.6184L17.6061 9.97518C18.0472 9.53402 18.7625 9.53402 19.2036 9.97518L26.0547 16.8261L28.2824 14.7194Z"></path>
                <path d="M14.6513 22.8817C15.1546 22.3784 15.1546 21.5594 14.6513 21.0561C14.1709 20.5553 13.3062 20.5554 12.8258 21.0561L9.761 24.1209C9.25773 24.6242 9.25773 25.4431 9.761 25.9465C10.2643 26.4496 11.0831 26.4498 11.5865 25.9465L14.6513 22.8817Z"></path>
                <path d="M7.25067 22.9014C7.59553 22.9014 7.9197 22.7671 8.1635 22.5233L11.5155 19.1713C11.8587 18.842 11.8587 18.2496 11.5155 17.9204L11.2282 17.633C10.7251 17.13 9.9064 17.1298 9.403 17.6328L6.33784 20.6978C5.83457 21.2012 5.83457 22.0201 6.33784 22.5234C6.58164 22.7671 6.9058 22.9014 7.25067 22.9014Z"></path>
              </svg>
              {/* <div className={classNames.title}>Consultation</div> */}

              {urllist.includes("/owner/consultation") && (
                <div className={classNames.title}>Consultation</div>
              )}
            </div>

            {/* <div
              style={{
                display:
                  accessPages?.title == "teamowner"
                    ? ""
                    : accessPages?.modules?.includes("Pharmacy")
                    ? ""
                    : "none",
              }}
              className={`${classNames.eachItem} ${
                urllist?.includes("/owner/pharmacy")
                  ? classNames.eachItemSelected
                  : ""
              }`}
              data-tooltip-id="pharmacy"
              onMouseEnter={() => {
                sethoverdata("pharmacy");
              }}
              onMouseLeave={() => {
                sethoverdata("");
              }}
              onClick={() => {
                setSelectedSidebarItem("pharmacy");

                seturllist("/owner/pharmacy");
              }}
            >
              <svg
                width="34"
                height="36"
                viewBox="0 0 34 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M30.1775 11.7913C29.6851 11.5334 29.4637 10.948 29.6619 10.4288C30.9986 7.0812 28.3948 3.25093 24.7924 3.24583C24.0806 3.23551 23.3872 3.36407 22.7331 3.628C22.1901 3.84706 21.5709 3.60109 21.3264 3.0691C19.46 -0.995308 13.651 -1.02869 11.7286 3.00379C11.4856 3.5139 10.8948 3.7558 10.3638 3.56262C9.76686 3.34528 9.13604 3.23847 8.49489 3.24556C5.61085 3.27667 3.26161 5.64902 3.25804 8.53396C3.25721 9.18694 3.374 9.82472 3.60504 10.4297C3.81012 10.9665 3.56594 11.5707 3.04538 11.8142C-2.09568 14.3843 -0.329851 21.7929 5.36914 21.9089H10.0448C16.5333 21.6393 16.5283 12.3967 10.0448 12.1296C9.4392 12.1296 8.94822 11.6387 8.94822 11.0331C8.94822 10.4275 9.4392 9.93648 10.0448 9.93648C13.5775 9.93648 16.5141 12.5361 17.0427 15.9227H22.1612C23.1436 15.9227 23.9428 15.1235 23.9428 14.1412C23.9428 13.1589 23.1436 12.3597 22.1612 12.3597C20.7071 12.3022 20.7067 10.2242 22.1612 10.1666C27.4345 10.3849 27.4323 17.8986 22.1612 18.1159H17.0427C16.815 19.5745 16.1407 20.8871 15.1644 21.9089H15.5949C15.6263 21.9062 15.6581 21.9062 15.6896 21.9089H27.6245C29.0701 21.9089 30.4279 21.3447 31.448 20.3204C32.468 19.296 33.0263 17.9357 33.02 16.4901C33.0199 14.5085 31.9307 12.7099 30.1775 11.7913Z"></path>
                <path d="M26.7865 30.6297H22.444V33.0083C22.444 34.2293 23.4338 35.2191 24.6549 35.2191H26.329C26.6153 35.2191 26.8475 34.987 26.8475 34.7006V30.6282C26.8271 30.6284 26.8069 30.6297 26.7865 30.6297Z"></path>
                <path d="M30.277 24.946V23.6272C29.4398 23.9389 28.5448 24.102 27.6244 24.102H16.8505C17.36 26.573 19.5525 28.4365 22.1721 28.4365H26.7864C28.7111 28.4365 30.277 26.8707 30.277 24.946Z"></path>
              </svg>

              {urllist.includes("/owner/pharmacy") && (
                <div className={classNames.title}>Pharmacy</div>
              )}
            </div>

            <div
              style={{
                display:
                  accessPages?.title == "teamowner"
                    ? ""
                    : accessPages?.modules?.includes("Accommodation")
                    ? ""
                    : "none",
              }}
              className={`${classNames.eachItem} ${
                urllist?.includes("/owner/accommodations")
                  ? classNames.eachItemSelected
                  : ""
              }`}
              data-tooltip-id="accommodations"
              onMouseEnter={() => {
                sethoverdata("accommodations");
              }}
              onMouseLeave={() => {
                sethoverdata("");
              }}
              onClick={() => {
                setSelectedSidebarItem("accommodations");
                seturllist("/owner/accommodations");
              }}
            >
              <svg
                width="34"
                height="37"
                viewBox="0 0 34 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M32.9279 35.2007L22.2656 14.7219C22.2339 14.6545 22.1978 14.5886 22.1518 14.5299C21.7527 14.0217 20.9578 14.0392 20.5864 14.5841C20.5617 14.6202 20.5416 14.6592 20.5223 14.6985L18.2866 18.8476L20.1563 22.2531C20.1577 22.2556 20.1591 22.2582 20.1605 22.2608L27.8507 36.3779H32.2137C32.8181 36.3778 33.207 35.7367 32.9279 35.2007Z"></path>
                <path d="M13.5592 10.79V7.55721L17.3029 7.56445L16.8917 9.04124C16.8245 9.28255 16.8327 9.54198 16.9324 9.77172C17.1057 10.1709 17.4935 10.4163 17.9116 10.4163H28.883C29.937 10.42 30.3821 9.10518 29.5342 8.4841L27.4472 6.73779C27.3153 6.6274 27.3168 6.42409 27.4504 6.31576L29.6387 4.5414C30.354 3.86868 29.8697 2.68733 28.8841 2.69444H22.8161L23.1768 1.36924C23.2424 1.12842 23.2331 0.870133 23.133 0.641536C22.959 0.244012 22.5721 0 22.1552 0H12.4937C11.9052 0 11.4281 0.477085 11.4281 1.06556V10.7883C11.7424 10.5957 12.1084 10.4899 12.4961 10.4908C12.8817 10.4915 13.2462 10.5979 13.5592 10.79Z"></path>
                <path d="M8.08265 28.4935C8.88508 28.5238 9.54751 27.8799 9.54751 27.084V22.6425C9.54751 20.9591 10.9251 19.5524 12.6081 19.5813C14.2428 19.6094 15.5641 20.9479 15.5641 22.5892V22.8997C15.6195 24.5842 17.9559 24.6939 18.1514 23.0294L13.41 13.4672C13.0116 12.7417 11.9699 12.7399 11.569 13.4641L6.72662 23.1401V27.0494C6.72655 27.8132 7.31936 28.4647 8.08265 28.4935Z"></path>
                <path d="M16.8272 26.359C14.9371 26.3412 13.4329 24.7605 13.4329 22.8703V22.6148C13.3907 21.4266 11.7342 21.4151 11.6785 22.5892V27.0247C11.4946 31.7736 4.81151 31.8118 4.59541 27.084V26.9908L0.100189 35.1963C-0.193833 35.7329 0.194526 36.3884 0.80644 36.3884H25.3753L19.3747 25.2575C18.7413 25.9418 17.8332 26.3686 16.8272 26.359Z"></path>
              </svg>
              {urllist.includes("/owner/accommodations") && (
                <div className={classNames.title}>Accommodations</div>
              )}
            </div>
            <div
              style={{
                display:
                  accessPages?.title == "teamowner"
                    ? ""
                    : accessPages?.modules?.includes("Therapy")
                    ? ""
                    : "none",
              }}
              className={`${classNames.eachItem} ${
                urllist?.includes("/owner/therapies")
                  ? classNames.eachItemSelected
                  : ""
              }`}
              data-tooltip-id="therapies"
              onMouseEnter={() => {
                sethoverdata("therapies");
              }}
              onMouseLeave={() => {
                sethoverdata("");
              }}
              onClick={() => {
                setSelectedSidebarItem("therapies");

                seturllist("/owner/therapies");
              }}
            >
              <svg
                width="34"
                height="35"
                viewBox="0 0 34 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20.0153 33.7524L22.3003 34.6548C22.4192 34.7018 22.546 34.7259 22.674 34.7259H25.5638C26.1256 34.7259 26.5811 34.2704 26.5811 33.7086V28.3857C24.9364 30.7252 22.6348 32.613 20.0153 33.7524Z"></path>
                <path d="M0.837402 28.3988V33.7086C0.837402 34.2704 1.29291 34.7259 1.85477 34.7259H4.74455C4.87254 34.7259 4.9993 34.7018 5.11826 34.6548L7.39844 33.7542C4.78212 32.6172 2.48241 30.7334 0.837402 28.3988Z"></path>
                <path d="M32.4262 4.5996L30.2293 4.22948L32.722 1.73674C33.1193 1.33943 33.1193 0.6953 32.722 0.297986C32.3248 -0.0993285 31.6806 -0.0993285 31.2833 0.297986L28.8259 2.75539L28.4182 0.580948C28.3695 0.321588 28.1815 0.110384 27.9295 0.0321147C27.6777 -0.0460866 27.403 0.0212629 27.2159 0.207372L23.8362 3.5703C23.6842 3.72155 23.6084 3.93329 23.6299 4.14667L23.9772 7.58841L22.6343 8.93519C13.9308 1.34865 -0.0551134 7.6542 0.000163355 19.3311C9.55311e-05 26.8998 6.13588 33.0356 13.7047 33.0356C25.4012 33.0887 31.6979 19.0671 24.0753 10.3716L25.4608 8.98206L29.102 9.20968C29.3061 9.2227 29.5084 9.14599 29.6528 8.99963L32.8141 5.80253C33.219 5.40223 32.9884 4.68784 32.4262 4.5996ZM25.1376 19.3309C24.5096 34.4978 2.89788 34.4939 2.272 19.3309C2.23049 9.68282 13.7312 4.37144 21.0211 10.5529L19.5782 12.0001C13.5539 7.08705 4.26956 11.4874 4.30673 19.3311C4.30666 24.5131 8.52261 28.729 13.7047 28.729C21.5614 28.7648 25.9567 19.4568 21.019 13.4369L22.4618 11.9898C24.1307 13.9774 25.1376 16.5386 25.1376 19.3309ZM15.9478 21.574C14.711 22.8109 12.6985 22.8109 11.4616 21.574C8.96754 18.981 11.8956 14.9644 15.1056 16.4857L13.3421 18.2543C13.1807 18.3084 13.0287 18.3984 12.9004 18.5267C12.4569 18.9702 12.4569 19.6918 12.9004 20.1353C13.4759 20.7322 14.5372 20.4805 14.782 19.692L16.5463 17.9226C17.133 19.1086 16.9341 20.5877 15.9478 21.574ZM16.7107 14.876C13.2107 12.4675 8.30341 15.0511 8.32884 19.331C8.32884 22.2953 10.7404 24.7069 13.7047 24.7069C17.9914 24.7317 20.5735 19.8116 18.1511 16.313L19.606 14.8539C23.2999 19.6336 19.7965 26.7732 13.7047 26.7416C9.61845 26.7416 6.29411 23.4172 6.29411 19.3309C6.26305 13.2485 13.3828 9.7421 18.1654 13.4169L16.7107 14.876Z"></path>
              </svg>

              {urllist.includes("/owner/therapies") && (
                <div className={classNames.title}>Therapies</div>
              )}
            </div>

            <div
              style={{
                display:
                  accessPages?.title == "teamowner"
                    ? ""
                    : accessPages?.modules?.includes("Program")
                    ? ""
                    : "none",
              }}
              className={`${classNames.eachItem} ${
                urllist?.includes("/owner/packages")
                  ? classNames.eachItemSelected
                  : ""
              }`}
              data-tooltip-id="Packages"
              onMouseEnter={() => {
                sethoverdata("Packages");
              }}
              onMouseLeave={() => {
                sethoverdata("");
              }}
              onClick={() => {
                setSelectedSidebarItem("Packages");

                seturllist("/owner/packages");
              }}
            >
              <svg
                width="34"
                height="33"
                viewBox="0 0 34 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M19.5132 17.9089H13.4521C14.4782 20.7282 18.4881 20.7265 19.5132 17.9089Z"></path>
                <path d="M21.7869 26.4778C21.623 27.2965 21.2752 28.0689 20.7659 28.7447H28.6505V26.4778H21.7869Z"></path>
                <path d="M4.31494 17.9089V20.0361H12.1362C11.6747 19.4153 11.3427 18.6929 11.181 17.9089H4.31494Z"></path>
                <path d="M33.02 17.9089H30.8412V21.1314C30.8412 21.7363 30.3508 22.2267 29.7459 22.2267H3.21959C2.61466 22.2267 2.12425 21.7363 2.12425 21.1314V17.9089H0V24.2871H33.02V17.9089Z"></path>
                <path d="M19.5215 26.4778H13.4439C14.5099 29.4828 18.4567 29.4808 19.5215 26.4778Z"></path>
                <path d="M0 26.4778V31.8337C0 32.4755 0.520262 32.9958 1.16201 32.9958H31.858C32.4997 32.9958 33.02 32.4755 33.02 31.8337V26.4778H30.8412V29.8401C30.8412 30.445 30.3508 30.9354 29.7459 30.9354H3.21959C2.61466 30.9354 2.12425 30.445 2.12425 29.8401V26.4778H0Z"></path>
                <path d="M4.31494 26.4778V28.7447H12.1994C11.6902 28.0688 11.3424 27.2965 11.1784 26.4778H4.31494Z"></path>
                <path d="M16.6021 11.2023C20.8823 10.8976 20.879 4.81887 16.6021 4.51573H16.3632C12.083 4.82042 12.0862 10.8992 16.3632 11.2023H16.6021Z"></path>
                <path d="M21.7843 17.9089C21.6226 18.6929 21.2906 19.4153 20.8292 20.0361H28.6504V17.9089H21.7843Z"></path>
                <path d="M6.43542 6.51089V3.48398L3.12201 6.81738H6.12893C6.2982 6.81738 6.43542 6.68016 6.43542 6.51089Z"></path>
                <path d="M28.6505 11.2023V4.51579H20.8927C22.3828 6.39023 22.3826 9.32822 20.8927 11.2024H28.6505V11.2023ZM25.3158 6.76366C25.9208 6.76366 26.4112 7.25407 26.4112 7.859C26.3535 9.31197 24.2779 9.31158 24.2204 7.859C24.2205 7.25407 24.7109 6.76366 25.3158 6.76366Z"></path>
                <path d="M8.62619 4.5158V7.91272C8.62619 8.51765 8.13578 9.00805 7.53085 9.00805H4.315V11.2023H12.0727C10.5826 9.3279 10.5828 6.38991 12.0727 4.51573L8.62619 4.5158Z"></path>
                <path d="M31.858 0H10.3819C10.0725 0 9.77587 0.123359 9.55772 0.3428L8.62611 1.28002V2.32506C13.4802 2.32499 24.7871 2.32506 29.7458 2.32506C30.3507 2.32506 30.8411 2.81546 30.8411 3.42039V12.2977C30.8411 12.9027 30.3507 13.3931 29.7458 13.3931C23.0707 13.3931 9.89085 13.3931 3.21952 13.3931C2.61459 13.3931 2.12419 12.9027 2.12419 12.2977V9.00812H0.944442L0.337899 9.61833C0.121489 9.83603 0 10.1305 0 10.4375V15.7181H33.02V1.16201C33.02 0.520262 32.4997 0 31.858 0Z"></path>
              </svg>
              

              {urllist.includes("/owner/packages") && (
                <div className={classNames.title}>Packages</div>
              )}
              
              <ReactTooltip
                id="Packages"
                place="right"
                variant="info"
                content="Packages"
              />
            </div>

            <div
              style={{
                display:
                  accessPages?.title == "teamowner"
                    ? ""
                    : accessPages?.modules?.includes("Kitchen")
                    ? ""
                    : "none",
              }}
              className={`${classNames.eachItem} ${
                urllist?.includes("/owner/kitchen")
                  ? classNames.eachItemSelected
                  : ""
              }`}
              data-tooltip-id="kitchen"
              onMouseEnter={() => {
                sethoverdata("kitchen");
              }}
              onMouseLeave={() => {
               
              }}
              onClick={() => {
                setSelectedSidebarItem("kitchen");
                
                seturllist("/owner/kitchen");
              }}
            >
              <svg
                width="34"
                height="37"
                viewBox="0 0 34 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M32.9279 35.2006L22.2656 14.7219C22.2339 14.6544 22.1977 14.5886 22.1517 14.5299C21.7527 14.0216 20.9577 14.0392 20.5863 14.5841C20.5617 14.6202 20.5415 14.6592 20.5222 14.6985L18.2865 18.8476L20.1562 22.2531C20.1576 22.2556 20.159 22.2582 20.1605 22.2608L27.8506 36.3779H32.2136C32.8181 36.3778 33.207 35.7367 32.9279 35.2006Z"></path>
                <path d="M13.5592 10.79V7.55721L17.3029 7.56445L16.8917 9.04124C16.8245 9.28255 16.8327 9.54198 16.9324 9.77172C17.1057 10.1709 17.4935 10.4163 17.9116 10.4163H28.883C29.937 10.42 30.3821 9.10518 29.5342 8.4841L27.4472 6.73779C27.3153 6.6274 27.3168 6.42409 27.4504 6.31576L29.6387 4.5414C30.354 3.86868 29.8697 2.68733 28.8841 2.69444H22.8161L23.1768 1.36924C23.2424 1.12842 23.2331 0.870133 23.133 0.641536C22.959 0.244012 22.5721 0 22.1552 0H12.4937C11.9052 0 11.4281 0.477085 11.4281 1.06556V10.7883C11.7424 10.5957 12.1084 10.4899 12.4961 10.4908C12.8817 10.4915 13.2462 10.5979 13.5592 10.79Z"></path>
                <path d="M8.08265 28.4935C8.88508 28.5238 9.54751 27.8798 9.54751 27.084V22.6424C9.54751 20.9591 10.9251 19.5524 12.6081 19.5813C14.2428 19.6094 15.5641 20.9478 15.5641 22.5891V22.8997C15.6195 24.5841 17.9559 24.6938 18.1514 23.0294L13.41 13.4672C13.0116 12.7416 11.9699 12.7399 11.569 13.4641L6.72662 23.14V27.0494C6.72655 27.8132 7.31936 28.4646 8.08265 28.4935Z"></path>
                <path d="M16.8272 26.3591C14.9371 26.3413 13.4329 24.7605 13.4329 22.8703V22.6149C13.3907 21.4266 11.7342 21.4151 11.6785 22.5892V27.0247C11.4946 31.7736 4.81151 31.8119 4.59541 27.0841V26.9909L0.100189 35.1963C-0.193833 35.7329 0.194526 36.3884 0.80644 36.3884H25.3753L19.3747 25.2575C18.7413 25.9419 17.8332 26.3686 16.8272 26.3591Z"></path>
              </svg>
              

              {urllist.includes("/owner/kitchen") && (
                <div className={classNames.title}>kitchen</div>
              )}

              <ReactTooltip
                id="kitchen"
                
                place="right"
                content="Kitchen"
              />
            
            </div> */}

            {/*  */}

            <div
              style={{
                display:
                  accessPages?.title == "teamowner"
                    ? ""
                    : accessPages?.modules?.includes("forms")
                    ? ""
                    : "none",
              }}
              className={`${classNames.eachItem} ${
                urllist?.includes("/owner/forms")
                  ? classNames.eachItemSelected
                  : ""
              }`}
              data-tooltip-id="Forms"
              onMouseEnter={() => {
                sethoverdata("Forms");
              }}
              onMouseLeave={() => {
                //sethoverdata("");
              }}
              onClick={() => {
                setSelectedSidebarItem("Forms");

                seturllist("/owner/forms");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="38"
                viewBox="0 0 34 38"
                fill="none"
              >
                <g>
                  <path d="M8.94648 15.4023C9.1914 15.4463 9.43743 15.3925 9.63938 15.2513C9.83839 15.1121 9.97099 14.9036 10.0128 14.6642C10.1004 14.162 9.75953 13.6796 9.25272 13.5889C9.01171 13.545 8.76184 13.5979 8.55982 13.7398C8.36081 13.8791 8.22821 14.0876 8.18642 14.3269C8.09871 14.8292 8.43968 15.3116 8.94648 15.4023Z" />
                  <path d="M17.6963 32.6823L17.6964 32.6824C17.9411 32.7259 18.1872 32.6725 18.3891 32.5315C18.5881 32.3922 18.7208 32.1837 18.7625 31.9443C18.8502 31.4422 18.5092 30.9598 18.0024 30.8689C17.7614 30.8252 17.5116 30.8779 17.3096 31.0199C17.1106 31.1591 16.978 31.3676 16.9362 31.607C16.8485 32.1093 17.1895 32.5917 17.6963 32.6823Z" />
                  <path d="M12.8629 23.1751C12.6181 23.1312 12.372 23.1849 12.1701 23.326C11.971 23.4652 11.8384 23.6737 11.7966 23.9131C11.709 24.4153 12.0499 24.8977 12.5567 24.9885C12.7286 25.0194 12.9006 25.0011 13.058 24.9393C13.1003 24.9153 13.1438 24.8944 13.1879 24.8763C13.4189 24.7431 13.5777 24.5129 13.6231 24.2505C13.7107 23.7483 13.3697 23.2658 12.8629 23.1751Z" />
                  <path d="M17.5258 17.0519C17.5291 17.0537 17.5322 17.0554 17.5355 17.0572C17.5739 17.0794 17.614 17.0997 17.6562 17.1164C17.7146 17.1396 17.7763 17.1572 17.8395 17.1685H17.8396C18.0846 17.2125 18.3305 17.1587 18.5325 17.0175C18.7315 16.8783 18.8641 16.6698 18.9059 16.4305C18.9935 15.9282 18.6526 15.4458 18.1458 15.3551C17.9048 15.3112 17.6549 15.3642 17.4529 15.506C17.2539 15.6453 17.1212 15.8538 17.0795 16.0931C17.0127 16.4755 17.197 16.8574 17.5258 17.0519Z" />
                  <path d="M29.1093 4.59784V37.6554H32.2221C32.6628 37.6554 33.02 37.2981 33.02 36.8574V5.39578C33.02 4.95509 32.6628 4.59784 32.2221 4.59784H29.1093Z" />
                  <path d="M0 5.30313V37.0193C0 37.4089 0.315755 37.7246 0.70529 37.7246H26.7995V4.59784H21.5702V5.84125C21.5702 7.33424 20.3599 8.54448 18.867 8.54448H8.14809C6.65511 8.54448 5.44486 7.33424 5.44486 5.84125V4.59784H0.70529C0.315755 4.59784 0 4.91367 0 5.30313ZM5.91098 13.9298C6.05912 13.0808 6.52959 12.3412 7.23569 11.8472C7.94466 11.3512 8.80569 11.1624 9.65972 11.3152C11.0149 11.5579 12.026 12.6155 12.2758 13.8911L15.3152 14.4353C15.5315 14.1186 15.805 13.8399 16.1288 13.6134C16.8378 13.1176 17.6986 12.9286 18.5528 13.0814C20.3078 13.3956 21.4869 15.0762 21.1812 16.8276C21.033 17.6765 20.5626 18.4162 19.8565 18.9102C19.1472 19.4081 18.2761 19.5947 17.4324 19.4422H17.4325C17.4193 19.4398 17.4063 19.4366 17.3931 19.4341L15.3709 22.2565C15.8368 22.9315 16.0496 23.7807 15.8984 24.6476C15.7985 25.2196 15.5518 25.7416 15.1857 26.1727L16.9158 28.6776C17.394 28.535 17.9028 28.5045 18.4095 28.5952C20.1646 28.9095 21.3436 30.5901 21.0379 32.3413C20.8897 33.1903 20.4192 33.93 19.713 34.424C19.1645 34.8077 18.525 35.0076 17.8682 35.0076C17.676 35.0076 17.4824 34.9905 17.2891 34.9558L17.2891 34.9559C15.5341 34.6417 14.3551 32.9611 14.6608 31.2097C14.7313 30.8059 14.8754 30.4273 15.0825 30.0876L13.1474 27.2859C12.8157 27.3299 12.4818 27.3215 12.1496 27.2621L12.1495 27.262C10.3946 26.9478 9.2155 25.2672 9.52123 23.516C9.66938 22.6669 10.14 21.9273 10.8462 21.4333C11.5552 20.9374 12.4162 20.7485 13.2701 20.9015C13.3376 20.9136 13.4039 20.9283 13.4696 20.9444L15.4252 18.215C15.0873 17.7715 14.8647 17.2449 14.7872 16.6873L11.8769 16.1662C11.6476 16.5478 11.3393 16.881 10.9634 17.1439C10.2541 17.6419 9.38296 17.8285 8.53932 17.676C6.78439 17.3618 5.60532 15.6813 5.91098 13.9298Z" />
                  <path d="M8.65304 2.22598C8.15685 2.22598 7.75464 2.62819 7.75464 3.12437V5.84131C7.75464 6.0586 7.9308 6.23475 8.14808 6.23475H18.8669C19.0842 6.23475 19.2603 6.0586 19.2603 5.84131V3.12437C19.2603 2.62819 18.8581 2.22598 18.3619 2.22598H16.7365C16.6229 2.22598 16.5219 2.15456 16.4833 2.04776C15.4927 -0.682597 11.5992 -0.682597 10.6089 2.04783C10.5702 2.15463 10.4693 2.22598 10.3557 2.22598H8.65304Z" />
                </g>
              </svg>
              {/* <div className={classNames.title}>Kitchen</div> */}

              {urllist.includes("/owner/forms") && (
                <div className={classNames.title}>Forms</div>
              )}

              <ReactTooltip id="Forms" place="right" content="Forms" />
            </div>

            <div
              className={`${classNames.eachItem} ${
                urllist?.includes("/owner/automation")
                  ? classNames.eachItemSelected
                  : ""
              }`}
              data-tooltip-id="Automation"
              onMouseEnter={() => {
                sethoverdata("Automation");
              }}
              onMouseLeave={() => {
                sethoverdata("");
              }}
              onClick={() => {
                setSelectedSidebarItem("Automation");
                //navigate("/owner/consultation/accountants");
                seturllist("/owner/automation");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
              >
                <path d="M6.31909 18.9927H11.0615V21.3514H6.31909V18.9927Z" />
                <path d="M6.31909 14.5784H11.0615V16.9371H6.31909V14.5784Z" />
                <path d="M6.31909 23.4069H11.0615V25.7656H6.31909V23.4069Z" />
                <path d="M19.1668 12.4698C18.9808 12.4358 18.788 12.4767 18.6321 12.5862C18.1143 12.9447 18.3142 13.7635 18.9318 13.8685C19.1212 13.9026 19.3107 13.861 19.4665 13.7521C19.9843 13.3936 19.7844 12.5749 19.1668 12.4698Z" />
                <path d="M33.02 7.07366V2.3828C33.02 1.06685 31.9532 0 30.6373 0H2.3828C1.06679 0 0 1.06685 0 2.3828V7.07366H33.02ZM12.7386 3.07765C13.3102 3.07765 13.7734 3.54063 13.7734 4.11175C13.7189 5.48347 11.7581 5.48309 11.7038 4.11175C11.7039 3.54063 12.1671 3.07765 12.7386 3.07765ZM8.34047 3.07765C8.91197 3.07765 9.37521 3.54063 9.37521 4.11175C9.32072 5.48347 7.35996 5.48309 7.30567 4.11175C7.30567 3.54063 7.76897 3.07765 8.34047 3.07765ZM4.13297 3.07765C4.70447 3.07765 5.16771 3.54063 5.16771 4.11175C5.11322 5.48347 3.15245 5.48309 3.09816 4.11175C3.09816 3.54063 3.56146 3.07765 4.13297 3.07765Z" />
                <path d="M26.2581 28.3374C26.4475 28.3715 26.637 28.3299 26.7928 28.221C26.9461 28.1138 27.0483 27.9531 27.0804 27.7687C27.1481 27.3811 26.8847 27.0086 26.4931 26.9385C26.3072 26.9047 26.1143 26.9455 25.9585 27.055C25.8052 27.1622 25.703 27.3228 25.6708 27.5072C25.6032 27.8949 25.8666 28.2674 26.2581 28.3374Z" />
                <path d="M0 9.12921V30.63C0 31.9459 1.06679 33.0128 2.3828 33.0128H30.6373C31.9532 33.0128 33.02 31.946 33.02 30.63V9.12921H0ZM13.117 26.7934C13.117 27.3611 12.6568 27.8212 12.0892 27.8212H5.29132C4.72368 27.8212 4.26354 27.3611 4.26354 26.7934V13.5507C4.26354 12.983 4.72368 12.5229 5.29132 12.5229H12.0893C12.6569 12.5229 13.117 12.983 13.117 13.5507L13.117 26.7934ZM29.2252 15.1322C29.0984 15.8591 28.6955 16.4924 28.0909 16.9153C27.4849 17.3407 26.741 17.5008 26.0201 17.3715L24.3741 19.6688C24.7561 20.2408 24.9288 20.953 24.8019 21.68C24.7191 22.154 24.5183 22.5878 24.2215 22.9499L25.6189 24.9732C26.016 24.8618 26.4366 24.8403 26.8554 24.9152C29.2615 25.3401 29.9805 28.5099 27.9711 29.9054C27.3638 30.3317 26.6181 30.4914 25.8959 30.3608C24.0208 30.052 23.0013 27.846 23.9866 26.227L22.4098 23.9439C22.1375 23.9759 21.8646 23.9671 21.5925 23.9186C20.0902 23.6495 19.0808 22.2109 19.3426 20.7116C19.5685 19.2382 21.0913 18.1801 22.552 18.473C22.5961 18.481 22.6398 18.49 22.683 18.4999L24.2703 16.2846C24.0049 15.9244 23.8278 15.5025 23.7584 15.0564L21.4053 14.6351C20.8332 15.5789 19.6519 16.1036 18.5695 15.8919C17.0673 15.6228 16.0579 14.1842 16.3197 12.6849C16.4465 11.958 16.8493 11.3248 17.4539 10.9018C18.061 10.4772 18.7982 10.3157 19.5292 10.4463C20.6746 10.6515 21.5328 11.5368 21.7601 12.6103L24.2255 13.0518C24.4072 12.794 24.6342 12.5669 24.9004 12.3806C25.5073 11.956 26.2443 11.7944 26.9755 11.9251C28.4776 12.1942 29.4869 13.6328 29.2252 15.1322Z" />
                <path d="M26.6131 13.9486C26.4271 13.9147 26.2343 13.9555 26.0785 14.065C25.9252 14.1722 25.823 14.3329 25.7908 14.5173C25.7393 14.8121 25.8814 15.1067 26.1349 15.2569C26.1375 15.2584 26.1402 15.2598 26.1427 15.2613C26.1725 15.2785 26.2035 15.2941 26.2362 15.3072C26.2816 15.3252 26.3292 15.3388 26.378 15.3475H26.3781C26.567 15.3815 26.7569 15.34 26.9127 15.2311C27.066 15.1238 27.1682 14.9631 27.2004 14.7788C27.2679 14.3911 27.0045 14.0187 26.6131 13.9486Z" />
                <path d="M22.1896 20.4965C22.0037 20.4626 21.8108 20.5034 21.655 20.6129C21.1372 20.9714 21.3371 21.7902 21.9547 21.8952C22.0857 21.9189 22.2167 21.9054 22.3367 21.8592C22.3723 21.8392 22.4086 21.8216 22.4456 21.8063C22.4603 21.7975 22.4751 21.7888 22.4893 21.7788C23.0071 21.4203 22.8072 20.6016 22.1896 20.4965Z" />
              </svg>

              {/* <div className={classNames.title}>Consultation</div> */}

              {urllist.includes("/owner/automation") && (
                <div className={classNames.title}>Automation</div>
              )}
            </div>
          </>
        );

      case "/settings/profiles":

      case "/settings/owner":
      case "/settings/accountants":
      case "/settings/admins":
      case "/settings/nonAdminStaff":
      case "/settings/superAdmin":
        return (
          <>
            <div
              className={`${classNames.eachItem} ${
                location?.pathname?.includes("/settings")
                  ? classNames.eachItemSelected
                  : ""
              }`}
              onClick={() => setSelectedSidebarItem("Profile")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="31"
                viewBox="0 0 34 31"
                fill="none"
              >
                <path d="M6.87916 15.1343C8.77506 15.1343 10.3187 13.5906 10.3187 11.6947C10.3187 9.79881 8.77506 8.25513 6.87916 8.25513C4.98326 8.25513 3.43958 9.79881 3.43958 11.6947C3.43958 13.5906 4.98326 15.1343 6.87916 15.1343Z" />
                <path d="M9.97479 16.5101H3.78354C1.6964 16.5101 0 18.2065 0 20.2937V20.9816C0 21.5512 0.46228 22.0135 1.03188 22.0135H12.7265C13.2961 22.0135 13.7583 21.5512 13.7583 20.9816V20.2937C13.7583 18.2065 12.0619 16.5101 9.97479 16.5101Z" />
                <path d="M26.5674 5.86117C26.3266 5.86117 26.0831 5.77725 25.8877 5.60527C23.2832 3.32138 19.9523 2.06387 16.51 2.06387C13.2658 2.06387 10.0931 3.20169 7.57534 5.26956C7.1337 5.63141 6.48293 5.56674 6.12246 5.12648C5.76062 4.68621 5.82528 4.03544 6.26555 3.6736C9.15067 1.30441 12.7898 0.00012207 16.51 0.00012207C20.4531 0.00012207 24.267 1.43924 27.2484 4.05333C27.6763 4.42893 27.7189 5.08108 27.3433 5.51034C27.1397 5.74148 26.8535 5.86117 26.5674 5.86117Z" />
                <path d="M9.97476 5.50344H6.53517C5.96558 5.50344 5.5033 5.04116 5.5033 4.47157V1.03198C5.5033 0.615103 5.75507 0.238125 6.14031 0.0785281C6.52554 -0.0810686 6.96994 0.00698473 7.26436 0.302789L10.7039 3.74237C10.9984 4.0368 11.0878 4.4812 10.9282 4.86643C10.7686 5.25166 10.3916 5.50344 9.97476 5.50344Z" />
                <path d="M6.53519 24.4075C6.77596 24.4075 7.01948 24.4914 7.21485 24.6634C9.81931 26.9473 13.1488 28.2048 16.5912 28.2048C19.8354 28.2048 23.008 27.067 25.5258 24.9991C25.9675 24.6359 26.6169 24.7005 26.9787 25.1422C27.3405 25.5824 27.2759 26.2332 26.8356 26.595C23.9519 28.9642 20.3128 30.2685 16.5925 30.2685C12.6494 30.2685 8.83559 28.8294 5.85415 26.2153C5.42627 25.8397 5.38362 25.1876 5.75922 24.7583C5.96284 24.5272 6.24902 24.4075 6.53519 24.4075Z" />
                <path d="M23.1277 24.7651H26.5673C27.1369 24.7651 27.5992 25.2274 27.5992 25.797V29.2366C27.5992 29.6535 27.3474 30.0305 26.9622 30.1901C26.577 30.3496 26.1326 30.2616 25.8381 29.9658L22.3986 26.5262C22.1041 26.2318 22.0147 25.7874 22.1743 25.4021C22.3339 25.0169 22.7109 24.7651 23.1277 24.7651Z" />
                <path d="M26.1408 15.1343C28.0367 15.1343 29.5804 13.5906 29.5804 11.6947C29.5804 9.79881 28.0367 8.25513 26.1408 8.25513C24.2449 8.25513 22.7012 9.79881 22.7012 11.6947C22.7012 13.5906 24.2449 15.1343 26.1408 15.1343Z" />
                <path d="M29.2365 16.5101H23.0452C20.9581 16.5101 19.2617 18.2065 19.2617 20.2937V20.9816C19.2617 21.5512 19.7239 22.0135 20.2935 22.0135H31.9881C32.5577 22.0135 33.02 21.5512 33.02 20.9816V20.2937C33.02 18.2065 31.3236 16.5101 29.2365 16.5101Z" />
              </svg>
              <div className={classNames.title}>Profile</div>
            </div>
          </>
        );

        return (
          <>
            <div
              className={`${classNames.eachItem} ${
                (selectedSidebarItem === "Locations" ||
                  organizationFilterType === "locations" ||
                  selectedSidebarItem === "") &&
                classNames.eachItemSelected
              }`}
              onClick={() => setSelectedSidebarItem("Locations")}
            >
              <OverviewIcon />
              <div className={classNames.title}>Locations</div>
            </div>
          </>
        );

      default:
        break;
    }
  }

  const [organization, setorganization] = useState({
    name: "",
    img: "",
    toggle: false,
  });

  const closeOrganization = () => {
    setorganization({
      name: organization?.name,
      img: organization?.img,
      toggle: false,
    });
  };
  const ref = useDetectClickOutside({
    onTriggered: closeOrganization,
    allowAnyKey: true,
  });
  const [organizationlist, setorganizationlist] = useState([]);
  useEffect(() => {
    if (accessPages?.title == "teamowner") {
      console.log("Dropdown values teamowner");
      callapiTeamOwner();
    } else {
      console.log("Dropdown values teamplayer");
      callapiTeamPlayer();
    }
  }, [accessPages]);

  const callapiTeamOwner = () => {
    // let loggedd = localStorage?.getItem("listorginzation")
    if (
      localStorage?.getItem("listorginzationName") == "" &&
      localStorage?.getItem("listorginzationImg") == ""
    ) {
      axios
        .get(
          `https://api.employment.group/api/getteamsbyowner?TeamCreatedBy=${localStorage?.getItem(
            "nvestBankLoginAccount"
          )}`
        )
        .then((res) => {
          console.log(res, "owner response");
          if (res?.data?.success) {
            if (accessPages?.title == "teamowner") {
              setorganizationlist(res?.data?.data);
              setdropDowndownvalue(res?.data?.data[0]?.NameOfTeam);
              localStorage.setItem(
                "listorginzationName",
                res?.data?.data[0]?.NameOfTeam
              );
              localStorage.setItem(
                "listorginzationImg",
                res?.data?.data[0]?.Icon
              );
              localStorage.setItem(
                "orginzationdetail",
                JSON.stringify(res?.data?.data[0])
              );
              localStorage.setItem(
                "dropdownlist",
                JSON.stringify(res?.data?.data)
              );
              setorganization({
                name: res?.data?.data[0]?.NameOfTeam,
                img: res?.data?.data[0]?.Icon,
                toggle: false,
              });
            }
          } else {
            setorganizationlist([]);
            setdropDowndownvalue("");
            localStorage.setItem("listorginzationName", "");
            localStorage.setItem("listorginzationImg", "");
            localStorage.setItem("orginzationdetail", JSON.stringify({}));
            localStorage.setItem("dropdownlist", JSON.stringify([]));
          }
        });
    } else {
      setorganizationlist(JSON.parse(localStorage?.getItem("dropdownlist")));
      setorganization({
        name: localStorage?.getItem("listorginzationName"),
        img: localStorage?.getItem("listorginzationImg"),
        toggle: false,
      });
      setdropDowndownvalue(localStorage?.getItem("listorginzationName"));
    }
  };

  const callapiTeamPlayer = () => {
    // let loggedd = localStorage?.getItem("listorginzation")

    axios
      .get(
        `https://api.employment.group/api/getteamsbyplayer?email=${localStorage?.getItem(
          "nvestBankLoginAccount"
        )}`
      )
      .then((res) => {
        console.log(res, "players response");
        if (res?.data?.success) {
          if (res?.data?.theplayers?.length > 0) {
            setorganizationlist(res?.data?.theplayers);

            if (
              res?.data?.theplayers[0].TeamDetails?.length > 0 &&
              res?.data?.theplayers[0].TeamDetails[0].TeamDetails?.length > 0
            )
              setdropDowndownvalue(
                res?.data?.theplayers[0].TeamDetails[0].TeamDetails[0]
                  ?.NameOfTeam
              );
            localStorage.setItem(
              "listorginzationName",
              res?.data?.theplayers[0].TeamDetails[0].TeamDetails[0]?.NameOfTeam
            );
            localStorage.setItem(
              "listorginzationImg",
              res?.data?.theplayers[0].TeamDetails[0].TeamDetails[0]?.Icon
            );
            localStorage.setItem(
              "orginzationdetail",
              JSON.stringify(
                res?.data?.theplayers[0].TeamDetails[0].TeamDetails[0]
              )
            );
            localStorage.setItem(
              "dropdownlist",
              JSON.stringify(
                res?.data?.theplayers[0].TeamDetails[0].TeamDetails
              )
            );
            setorganization({
              name: res?.data?.theplayers[0].TeamDetails[0].TeamDetails[0]
                ?.NameOfTeam,
              img: res?.data?.theplayers[0].TeamDetails[0].TeamDetails[0]?.Icon,
              toggle: false,
            });
          }
        } else {
          setorganizationlist([]);
          setdropDowndownvalue("");
          localStorage.setItem("listorginzationName", "");
          localStorage.setItem("listorginzationImg", "");
          localStorage.setItem("orginzationdetail", JSON.stringify({}));
          localStorage.setItem("dropdownlist", JSON.stringify([]));
        }
      });

    //  setorganizationlist(JSON.parse(localStorage?.getItem("dropdownlist")));
    //  setorganization({
    //    name: localStorage?.getItem("listorginzationName"),
    //    img: localStorage?.getItem("listorginzationImg"),
    //    toggle: false,
    //  });
    // setdropDowndownvalue(localStorage?.getItem("listorginzationName"));
  };

  const [organizationSelected, setOrganizationSelected] = useState("");

  //

  // async function playerToTeam(teamUsername) {
  //   alert(teamUsername)
  //   let result = await filterDetails(allLeads?.data, actionBtns?.[0]);

  //   let response = await axios.post(
  //     "https://api.employment.group/api/addplayerstoteam",
  //     {
  //       TeamOwnerEmail: email,
  //       PlayerUsername: result?.[0]?.username,
  //       StartDate: "",
  //       TeamUsername: teamUsername,
  //     }
  //   );
  //   if (response?.data?.success) {
  //     toastify("Added player to the selected team", "success");
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 2000);
  //   } else {
  //     toastify("Player data not found", "error");
  //   }
  //   console.log(response, "response from make user to player");
  // }

  const selectOrganzitionfunction = (item) => {
    // playerToTeam(item?.TeamUsername)
    setorganization({
      name: item?.NameOfTeam,
      img: item?.Icon,
      toggle: false,
    });
    localStorage.setItem("listorginzationName", item?.NameOfTeam);
    setdropDowndownvalue(item?.NameOfTeam);
    localStorage.setItem("listorginzationImg", item?.Icon);
    localStorage.setItem("orginzationdetail", JSON.stringify(item));
    // localStorage.setItem("listorginzationName", JSON.stringify(item?.NameOfTeam));
    // localStorage.setItem("listorginzationImg", JSON.stringify(item?.Icon));
  };

  if (!email) {
    return <Navigate to="/" />;
  }
  return (
    <div className={classNames.postLogin}>
      <div className={classNames.navbar}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={logoWithName}
            alt="logoWithName"
            style={{
              width: "8rem",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/settings/profiles");
              seturllist("/settings/profiles");
            }}
          />
          <input
            className={classNames.inputContainer}
            placeholder="Search Accute.."
            type="text"
            value={globalSearchQuery}
            onChange={(event) => {
              setGlobalSearchQuery(event?.target?.value);
            }}
          />
        </div>

        <div
          className={classNames.menuBar}
          // onMouseLeave={() => {
          //   setDropdownActive(false);
          // }}
        >
          <div className="dropdownParentSectioncustom" ref={ref}>
            <div
              className="dropdown-planb"
              onClick={() =>
                setorganization({
                  toggle: !organization?.toggle,
                  name: organization?.name,
                  img: organization?.img,
                })
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={organization?.img}
                  style={{
                    height: "15px",
                    marginRight: "10px",
                  }}
                />
                {organization.name == ""
                  ? "Click To Select"
                  : organization.name}
              </div>
              {organization.toggle ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="5"
                  viewBox="0 0 6 5"
                  fill="none"
                >
                  <path
                    d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                    fill="#fff"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="5"
                  viewBox="0 0 6 5"
                  fill="none"
                >
                  <path
                    d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                    fill="#fff"
                  />
                </svg>
              )}
            </div>
            {organization.toggle && (
              <div className="dropdwonlist">
                {organizationlist?.map((item) => {
                  return (
                    <>
                      <div
                        className="listitemName"
                        onClick={() => selectOrganzitionfunction(item)}
                      >
                        {item?.NameOfTeam}
                      </div>
                    </>
                  );
                })}
              </div>
            )}
          </div>

          <div className={classNames.menuBtn} ref={dropdownMenuButtonRef}>
            {/* <div
              onClick={() => {
                setDropdownActive(!dropdownActive);
                setSelectedMenuItemHover(selectedMenuItem);
                setSelectedMenuItemSubHover(selectedMenuItemSub);
              }}
            >

              {selectedMenuItem}

              <svg
                style={{
                  transform: dropdownActive && "rotate(58deg)"
                }}
                xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6" fill="none">
                <path d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214563 6.38681 0.00214563L0.613191 0.00214563C0.124668 -0.0430289 -0.186232 0.634684 0.124669 1.1317Z" fill="#fff" />
              </svg>
            </div> */}
            <div
              onClick={() => {
                setDropdownActive(!dropdownActive);
                setSelectedMenuItemHover(selectedMenuItem);
                setSelectedMenuItemSubHover(selectedMenuItemSub);
              }}
            >
              {profiledata == " " || !profiledata ? (
                <img
                  src=""
                  style={{
                    width: "39px",
                    height: "39px",
                    borderRadius: "5rem",
                    border: "1px solid #fff",
                    padding: "18px",
                    background: "grey",
                  }}
                />
              ) : (
                <img
                  src={profiledata}
                  style={{
                    width: "39px",
                    height: "39px",
                    borderRadius: "5rem",
                  }}
                />
              )}
            </div>
            {/* <div
              style={{
                display: selectedMenuItemSub ? "" : "none",
                borderRight: "0.5px solid #e5e5e5",
              }}
              onClick={() => {
                setDropdownActive(true);
                setSelectedMenuItemHover(selectedMenuItem);
                setSelectedMenuItemSubHover(selectedMenuItemSub);
              }}
            >
              {selectedMenuItemSub}
            </div> */}
            <div
              className={classNames.profileDiv}
              style={
                {
                  // background: "#c57e50"
                }
              }
              onClick={logoutFunction}
              // onClick={() => setOpenNotification(true)}
              ref={notificationButtonRef}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="21"
                viewBox="0 0 16 21"
                fill="none"
              >
                <path
                  d="M15.3125 7.875H14V6.12499C14 2.74764 11.2524 0 7.875 0C4.49761 0 1.75001 2.74764 1.75001 6.12499V7.875H0.437514C0.195686 7.875 0 8.07069 0 8.31251V19.25C0 20.2152 0.784834 21 1.75001 21H14C14.9652 21 15.75 20.2152 15.75 19.25V8.31251C15.75 8.07069 15.5543 7.875 15.3125 7.875ZM9.18496 17.0142C9.19862 17.1377 9.15891 17.2616 9.07602 17.3543C8.99313 17.447 8.87435 17.5 8.75003 17.5H7.00001C6.8757 17.5 6.75691 17.447 6.67402 17.3543C6.59113 17.2616 6.55138 17.1377 6.56508 17.0142L6.84108 14.5324C6.3929 14.2064 6.12503 13.6907 6.12503 13.125C6.12503 12.1599 6.90986 11.375 7.87504 11.375C8.84022 11.375 9.62505 12.1598 9.62505 13.125C9.62505 13.6907 9.35718 14.2064 8.909 14.5324L9.18496 17.0142ZM11.375 7.875H4.37501V6.12499C4.37501 4.19512 5.94513 2.625 7.875 2.625C9.80487 2.625 11.375 4.19512 11.375 6.12499V7.875Z"
                  fill="#fff"
                />
              </svg>
              {/* <img
                src={profileIcon}
                alt="profileIcon"
                className={classNames.notificationIcon}
              /> */}
              {openNotification && (
                <div
                  className={classNames.dropdownContent}
                  ref={notificationRef}
                >
                  <div className={classNames.singleNotification}>
                    <div className={classNames.heading}>
                      <div className={classNames.title}>
                        <img src={headspaceIcon} alt="headspaceIcon" />
                        Headspace
                      </div>
                      <div>
                        <BsDot />
                      </div>
                      <div className={classNames.duration}>
                        Now <MdKeyboardArrowUp />
                      </div>
                      <div className={classNames.closeBtn}>X</div>
                    </div>
                    <div className={classNames.notificationTitle}>
                      New Guide to Meditation Video Available!
                    </div>
                    <div className={classNames.notificationMessage}>
                      Exploreour latest meditation video guide on Headspace.
                      Whether you're a seasoned meditator or just starting out,
                      this video offers valuable insights and techniques to help
                      you find inner peace and mindfulness. Check it out now and
                      enhance your meditation journey with Headspace!
                    </div>
                    <img
                      src={tempNotoficationImage}
                      alt=""
                      className={classNames.notificationImage}
                    />
                    <div className={classNames.notificationBtn}>
                      Share with Employees
                    </div>
                  </div>
                  <div className={classNames.singleNotification}>
                    <div className={classNames.heading}>
                      <div className={classNames.title}>
                        <img src={rahaIcon} alt="rahaIcon" />
                        Raha
                      </div>
                      <div>
                        <BsDot />
                      </div>
                      <div className={classNames.duration}>
                        2 Days Ago <MdKeyboardArrowUp />
                      </div>
                      <div className={classNames.closeBtn}>X</div>
                    </div>
                    <div className={classNames.notificationTitle}>
                      New Endorsement Created
                    </div>
                    <div className={classNames.notificationMessage}>
                      You have successfully created endorsement on 02/09/2024
                      for 10 new Employees joining the company and 4 employees
                      leaving.
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {dropdownActive && (
            <div className={classNames.menuDropdown} ref={dropdownMenuRef}>
              <div className={classNames.mainMenu}>
                {mainMenuItems?.map((eachItem, index) => {
                  return (
                    <div
                      className={`${classNames.eachItem} ${
                        // selectedMenuItem === eachItem?.name ||
                        selectedMenuItemHover === eachItem?.name &&
                        classNames.eachItemSelected
                      }`}
                      key={eachItem?.name + index}
                      onClick={() => {
                        setSelectedMenuItem(eachItem?.name);
                        setSelectedMenuItemSub(eachItem?.subMenuItem);
                        localStorage.setItem(
                          "selectedMenuItem",
                          eachItem?.name
                        );
                        localStorage.setItem(
                          "selectedMenuItemSub",
                          eachItem?.subMenuItem
                        );
                        if (eachItem?.name === "Dashboard") {
                          navigate("/owner/bookings/dashboard");
                          seturllist("/owner/bookings/dashboard");
                        }
                        setDropdownActive(false);
                      }}
                      onMouseEnter={() => {
                        setSelectedMenuItemHover(eachItem?.name);
                        setSelectedMenuItemSubHover(selectedMenuItemSub);
                      }}
                    >
                      {/* <div className={classNames.imageDiv}>
                        <img src={eachItem?.image} alt={eachItem?.name} />
                      </div> */}
                      <div>{eachItem?.name}</div>
                    </div>
                  );
                })}
                <div
                  className={classNames.settingsButton}
                  onClick={() => {
                    setSelectedMenuItem("Settings");
                    setSelectedMenuItemSub("Profile");
                    localStorage.setItem("selectedMenuItem", "Settings");
                    localStorage.setItem("selectedMenuItemSub", "Profile");
                    navigate("/settings/profiles");
                    seturllist("/settings/profiles");

                    setDropdownActive(false);
                  }}
                >
                  Settings
                </div>
              </div>
              {/* <div className={classNames.subMenu}>
                {selectedMenuItemHover === "Owner" ? (
                  <>
                    {subMenuItemsClients?.map((eachItem, index) => {
                      return (
                        <div
                          key={eachItem?.name + index}
                          onClick={() => {
                            setSelectedMenuItem("Owner");
                            setSelectedMenuItemSub(eachItem?.name);
                            localStorage.setItem(
                              "selectedMenuItemSub",
                              eachItem?.name
                            );
                            if (eachItem?.link) {
                              setDropdownActive(false);
                              navigate(eachItem?.link);
                            }
                          }}
                          onMouseEnter={() => {
                            setSelectedMenuItemSubHover(eachItem?.name);
                          }}
                          className={
                            selectedMenuItemSubHover === eachItem?.name
                              ? classNames.subHeading
                              : ""
                          }
                        >
                          {eachItem?.name}
                        </div>
                      );
                    })}
                  </>
                ) : selectedMenuItemHover === "Accountants" ? (
                  <>
                    {subMenuItemsCPAAdmin?.map((eachItem, index) => {
                      return (
                        <div
                          key={eachItem?.name + index}
                          onClick={() => {
                            setSelectedMenuItem("Accountants");
                            setSelectedMenuItemSub(eachItem?.name);
                            localStorage.setItem(
                              "selectedMenuItemSub",
                              eachItem?.name
                            );
                            if (eachItem?.link) {
                              setDropdownActive(false);
                              navigate(eachItem?.link);
                            }
                          }}
                          onMouseEnter={() => {
                            setSelectedMenuItemSubHover(eachItem?.name);
                          }}
                          className={
                            selectedMenuItemSubHover === eachItem?.name
                              ? classNames.subHeading
                              : ""
                          }
                        >
                          {eachItem?.name}
                        </div>
                      );
                    })}
                  </>
                ) : selectedMenuItemHover === "Admins" ? (
                  <>
                    {subMenuItemsCPAEmployees?.map((eachItem, index) => {
                      return (
                        <div
                          key={eachItem?.name + index}
                          onClick={() => {
                            setSelectedMenuItem("Admins");
                            setSelectedMenuItemSub(eachItem?.name);
                            localStorage.setItem(
                              "selectedMenuItemSub",
                              eachItem?.name
                            );
                            if (eachItem?.link) {
                              setDropdownActive(false);
                              navigate(eachItem?.link);
                            }
                          }}
                          onMouseEnter={() => {
                            setSelectedMenuItemSubHover(eachItem?.name);
                          }}
                          className={
                            selectedMenuItemSubHover === eachItem?.name
                              ? classNames.subHeading
                              : ""
                          }
                        >
                          {eachItem?.name}
                        </div>
                      );
                    })}
                  </>
                ) : selectedMenuItemHover === "Non-Admin Staff" ? (
                  <>
                    {subMenuItemsAccountants?.map((eachItem, index) => {
                      return (
                        <div
                          key={eachItem?.name + index}
                          onClick={() => {
                            setSelectedMenuItem("Non-Admin Staff");
                            setSelectedMenuItemSub(eachItem?.name);
                            localStorage.setItem(
                              "selectedMenuItemSub",
                              eachItem?.name
                            );
                            if (eachItem?.link) {
                              setDropdownActive(false);
                              navigate(eachItem?.link);
                            }
                          }}
                          onMouseEnter={() => {
                            setSelectedMenuItemSubHover(eachItem?.name);
                          }}
                          className={
                            selectedMenuItemSubHover === eachItem?.name
                              ? classNames.subHeading
                              : ""
                          }
                        >
                          {eachItem?.name}
                        </div>
                      );
                    })}
                  </>
                ) : selectedMenuItemHover === "Super Admin" ? (
                  <>
                    {subMenuItemsAdmin?.map((eachItem, index) => {
                      return (
                        <div
                          key={eachItem?.name + index}
                          onClick={() => {
                            setSelectedMenuItem("Super Admin");
                            setSelectedMenuItemSub(eachItem?.name);
                            localStorage.setItem(
                              "selectedMenuItemSub",
                              eachItem?.name
                            );
                            if (eachItem?.link) {
                              setDropdownActive(false);
                              navigate(eachItem?.link);
                            }
                          }}
                          onMouseEnter={() => {
                            setSelectedMenuItemSubHover(eachItem?.name);
                          }}
                          className={
                            selectedMenuItemSubHover === eachItem?.name
                              ? classNames.subHeading
                              : ""
                          }
                        >
                          {eachItem?.name}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </div> */}
            </div>
          )}
        </div>
      </div>
      <div className={classNames.layoutContainer}>
        <div className={classNames.sidebar}>
          <div className={classNames.mainMenu}>
            <div
              className={classNames.sidebarMenu}
              // onClick={() => {
              //   setSelectedMenuItem("Owner");
              //   setSelectedMenuItemSub(eachItem?.name);
              //   localStorage.setItem("selectedMenuItemSub", eachItem?.name);
              //   if (eachItem?.link) {
              //     setDropdownActive(false);
              //     navigate(eachItem?.link);
              //   }
              // }}
            >
              {renderSidebar()}
            </div>
            {/* <div className={classNames.settingsMenu}>
              <div className={classNames.eachItem} onClick={logoutFunction}>
                <LockIcon />
              </div>
            </div> */}
            {/* <div
              className={classNames.arrowBox}
              onClick={() => {
                setExpandSidebar((prev) => !prev);
              }}
            >
              <IoIosArrowBack
                style={{ rotate: expandSidebar ? "" : "180deg" }}
              />
            </div> */}
          </div>

          <div ref={clickRef} className={classNames.collapseSidebar}>
            {location?.pathname?.includes("/settings") && (
              <div className={classNames.collapseSidebarOptions}>
                <Link
                  to="/settings/profiles"
                  className={
                    location?.pathname === "/settings/profiles"
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  User
                </Link>
                {accessPages?.title == "teamowner" && (
                  <Link
                    to="/settings/owner"
                    className={
                      location?.pathname === "/settings/owner"
                        ? classNames.selectedCollapsedItem
                        : ""
                    }
                    onClick={() => setExpandSidebar(false)}
                  >
                    Owner
                  </Link>
                )}
                {/* <Link
                    to="/settings/accountants"
                    className={
                      location?.pathname === "/settings/accountants"
                        ? classNames.selectedCollapsedItem
                        : ""
                    }
                    onClick={() => setExpandSidebar(false)}
                  >
                    Accountants
                  </Link>
                  <Link
                    to="/settings/admins"
                    className={
                      location?.pathname === "/settings/admins"
                        ? classNames.selectedCollapsedItem
                        : ""
                    }
                    onClick={() => setExpandSidebar(false)}
                  >
                    Admins
                  </Link> */}
                {/* <Link
                    to="/settings/nonAdminStaff"
                    className={
                      location?.pathname === "/settings/nonAdminStaff"
                        ? classNames.selectedCollapsedItem
                        : ""
                    }
                    onClick={() => setExpandSidebar(false)}
                  >
                    Non-Admin Staff
                  </Link>      <Link
                    to="/settings/superAdmin"
                    className={
                      location?.pathname === "/settings/superAdmin"
                        ? classNames.selectedCollapsedItem
                        : ""
                    }
                    onClick={() => setExpandSidebar(false)}
                  >
                    Super Admin
                  </Link> */}
              </div>
            )}
            {urllist?.includes("/owner/automation") && (
              <div className={classNames.collapseSidebarOptions}>
                <Link
                  to="/owner/automation/pipelines"
                  className={
                    location?.pathname === "/owner/automation/pipelines"
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Pipelines
                </Link>
                <Link
                  to="/owner/automation/stages"
                  className={
                    location?.pathname === "/owner/automation/stages"
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Stages
                </Link>
                <Link
                  to="/owner/automation/steps"
                  className={
                    location?.pathname === "/owner/automation/steps"
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Steps
                </Link>

                <Link
                  to="/owner/automation/automatedtasks"
                  className={
                    location?.pathname === "/owner/automation/automatedtasks"
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Automated Tasks
                </Link>
                <Link
                  to="/owner/automation/manualtasks"
                  className={
                    location?.pathname === "/owner/automation/manualtasks"
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Manual Tasks
                </Link>
                <Link
                  className={
                    location?.pathname === "/owner/automation/ssd"
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Forms
                </Link>
                <Link
                  className={
                    location?.pathname === "/owner/automation/stsdages"
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Folders
                </Link>
                <Link
                  className={
                    location?.pathname === "/owner/automation/stsages"
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Sub Tasks
                </Link>
              </div>
            )}

            {urllist?.includes("/owner/packages") && (
              <div className={classNames.collapseSidebarOptions}>
                <Link
                  to="/owner/packages"
                  className={
                    location?.pathname === "/owner/packages" ||
                    location?.pathname === `/owner/packages/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Packages
                </Link>
              </div>
            )}

            {urllist?.includes("/owner/forms") && (
              <div className={classNames.collapseSidebarOptions}>
                <Link
                  className={
                    location?.pathname === "/owner/forms/genericforms" ||
                    location?.pathname === `/owner/forms/genericforms/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  to="/owner/forms/genericforms"
                  onClick={() => setExpandSidebar(false)}
                >
                  Generic Forms
                </Link>

                {/* <Link
                  className={
                    location?.pathname === "/owner/forms/patientProfileForms" ||
                    location?.pathname ===
                      `/owner/forms/patientProfileForms/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  to="/owner/forms/patientProfileForms"
                  onClick={() => setExpandSidebar(false)}
                >
                  Client Profile Forms
                </Link>

                <Link
                  className={
                    location?.pathname === "/owner/forms/widgetForms" ||
                    location?.pathname === `/owner/forms/widgetForms/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  to="/owner/forms/widgetForms"
                  onClick={() => setExpandSidebar(false)}
                >
                  Widget Forms
                </Link>

                <Link
                  className={
                    location?.pathname ===
                      "/owner/forms/consultationSummaryForms" ||
                    location?.pathname ===
                      `/owner/forms/consultationSummaryForms/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  to="/owner/forms/consultationSummaryForms"
                  onClick={() => setExpandSidebar(false)}
                >
                  Consultation Summary Forms
                </Link> */}
              </div>
            )}

            {urllist?.includes("/owner/kitchen") && (
              <div className={classNames.collapseSidebarOptions}>
                <Link
                  to="/owner/kitchen/items"
                  className={
                    location?.pathname === "/owner/kitchen/items" ||
                    location?.pathname === `/owner/kitchen/items/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Items
                </Link>
                <Link
                  to="/owner/kitchen/dietTemplates"
                  className={
                    location?.pathname === "/owner/kitchen/dietTemplates" ||
                    location?.pathname === `/owner/kitchen/dietTemplates/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Diet Templates
                </Link>
              </div>
            )}

            {urllist?.includes("/owner/client") && (
              <div className={classNames.collapseSidebarOptions}>
                <Link
                  to="/owner/client/users"
                  className={
                    location?.pathname === "/owner/client/users" ||
                    location?.pathname === `/owner/client/users/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  CRM
                </Link>
                {/* <Link
                
                  onClick={() => setExpandSidebar(false)}
                >
                  Profile Structure
                </Link> */}
              </div>
            )}

            {urllist?.includes("/owner/therapies") && (
              <div className={classNames.collapseSidebarOptions}>
                <Link
                  className={
                    location?.pathname === "/owner/therapies/categories" ||
                    location?.pathname === `/owner/therapies/categories/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  to="/owner/therapies/categories"
                  onClick={() => setExpandSidebar(false)}
                >
                  Therapy Categories
                </Link>
                <Link
                  className={
                    location?.pathname === "/owner/therapies/offerings" ||
                    location?.pathname === `/owner/therapies/offerings/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  to="/owner/therapies/offerings"
                  onClick={() => setExpandSidebar(false)}
                >
                  Therapy Services
                </Link>

                <Link
                  className={
                    location?.pathname === "/owner/therapies/therapySessions"
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  to="/owner/therapies/therapySessions"
                  onClick={() => setExpandSidebar(false)}
                >
                  Therapy Sessions
                </Link>
              </div>
            )}

            {urllist?.includes("/owner/accommodations") && (
              <div className={classNames.collapseSidebarOptions}>
                <Link
                  className={
                    location?.pathname === "/owner/accommodations/categories" ||
                    location?.pathname ==
                      `/owner/accommodations/categories/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  to="/owner/accommodations/categories"
                  onClick={() => setExpandSidebar(false)}
                >
                  Categories
                </Link>
                <Link
                  className={
                    location?.pathname == "/owner/accommodations/rooms" ||
                    location?.pathname == `/owner/accommodations/rooms/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  to="/owner/accommodations/rooms"
                  onClick={() => setExpandSidebar(false)}
                >
                  Rooms
                </Link>
              </div>
            )}
            {urllist.includes("/owner/pharmacy") && (
              <div className={classNames.collapseSidebarOptions}>
                <Link
                  className={
                    location?.pathname == "/owner/pharmacy/brands" ||
                    location?.pathname == `/owner/pharmacy/brands/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  to="/owner/pharmacy/brands"
                  onClick={() => setExpandSidebar(false)}
                >
                  Brands
                </Link>
                <Link
                  className={
                    location?.pathname === "/owner/pharmacy/Categories" ||
                    location?.pathname == `/owner/pharmacy/Categories/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  to="/owner/pharmacy/Categories"
                  onClick={() => setExpandSidebar(false)}
                >
                  Categories
                </Link>
                <Link
                  className={
                    location?.pathname === "/owner/pharmacy/products" ||
                    location?.pathname == `/owner/pharmacy/products/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  to="/owner/pharmacy/products"
                  onClick={() => setExpandSidebar(false)}
                >
                  Products
                </Link>
                <Link
                  className={
                    location?.pathname === "/owner/pharmacy/prescriptions" ||
                    location?.pathname == `/owner/pharmacy/prescriptions/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  to="/owner/pharmacy/prescriptions"
                  onClick={() => setExpandSidebar(false)}
                >
                  Prescriptions
                </Link>
              </div>
            )}

            {urllist.includes("/owner/consultation") && (
              <div className={classNames.collapseSidebarOptions}>
                {/* <Link


                    onClick={() => setExpandSidebar(false)}
                  >
                    Dashboard
                  </Link> */}
                <Link
                  className={
                    location?.pathname === "/owner/consultation/sessions" ||
                    location?.pathname === `/owner/consultation/sessions/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  to="/owner/consultation/sessions"
                  onClick={() => setExpandSidebar(false)}
                >
                  Consultation Sessions
                </Link>

                <Link
                  to="/owner/consultation/serviceTypes"
                  className={
                    location?.pathname === "/owner/consultation/serviceTypes" ||
                    location?.pathname ===
                      `/owner/consultation/serviceTypes/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Consultation Categories
                </Link>
                <Link
                  to="/owner/consultation/services"
                  className={
                    location?.pathname === "/owner/consultation/services" ||
                    location?.pathname === `/owner/consultation/services/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Consultation Services
                </Link>
              </div>
            )}
            {urllist?.includes("/owner/bookings") && (
              <div className={classNames.collapseSidebarOptions}>
                <Link
                  to="/owner/bookings/dashboard"
                  className={
                    location?.pathname === "/owner/bookings/dashboard"
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Dashboard
                </Link>

                {/* <Link

                  onClick={() => setExpandSidebar(false)}
                >
                  Leads
                </Link> */}
                <Link
                  to="/owner/bookings/calendars"
                  className={
                    location?.pathname === "/owner/bookings/calendars"
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Calendars
                </Link>
              </div>
            )}

            {urllist.includes("/owner/staffing") ? (
              <div className={classNames.collapseSidebarOptions}>
                <Link
                  to="/owner/staffing/staff"
                  className={
                    location?.pathname === "/owner/staffing/staff" ||
                    location?.pathname == `/owner/staffing/staff/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Staff
                </Link>
                <Link
                  to="/owner/staffing/staffgroups"
                  className={
                    location?.pathname == "/owner/staffing/staffgroups" ||
                    location?.pathname == `/owner/staffing/staffgroups/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Staff groups
                </Link>
                <Link
                  to="/owner/staffing/staffroles"
                  className={
                    location?.pathname === "/owner/staffing/staffroles" ||
                    location?.pathname == `/owner/staffing/staffroles/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Staff roles
                </Link>
                <Link
                  to="/owner/staffing/calendar"
                  className={
                    location?.pathname === "/owner/staffing/calendar" ||
                    location?.pathname == `/owner/staffing/calendar/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Calendars
                </Link>
                <Link
                  className={
                    location?.pathname === "/owner/staffing/accountants" ||
                    location?.pathname == `/owner/staffing/accountants/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  to="/owner/staffing/accountants"
                  onClick={() => setExpandSidebar(false)}
                >
                  Accountants
                </Link>

                <Link
                  to="/owner/staffings/locations"
                  className={
                    location?.pathname === "/owner/staffings/locations" ||
                    location?.pathname == `/owner/staffing/locations/${id}`
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Locations
                </Link>
              </div>
            ) : urllist?.includes("/owner/client") ? (
              <div className={classNames.collapseSidebarOptions}></div>
            ) : urllist?.includes("/owner/organization") ? (
              <div className={classNames.collapseSidebarOptions}>
                {/* <Link
                  to="/owner/organization/users"
                  className={
                    location?.pathname === "/owner/organization/users"
                      ? classNames.selectedCollapsedItem
                      : ""
                  }
                  onClick={() => setExpandSidebar(false)}
                >
                  Users
                </Link> */}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={classNames.childContainer}>{child}</div>
      </div>
      {accessPages?.modules?.length == 0 && (
        <div className={classNames.overFlowMain}>
          <div className={classNames.contentContainer}>
            <img src={montageLogo} alt="montageLogo" />
            <div className={classNames.desc}>
              Looks like you haven’t been assigned any modules. Please contact
              your admin.
            </div>
            <div className={classNames.submitBtn} onClick={logoutFunction}>
              Logout
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PostLogin;
