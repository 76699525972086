import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./flow.scss";
import classNames from "./flow.module.scss";
import axios from "axios";

const KanbanBoard = ({ stateValue, setState, setLocalSidebar }) => {
  const [stages, setStages] = useState([
    { id: 1, title: "Stage 1", items: [] },
    { id: 2, title: "Stage 2", items: [] },
    { id: 3, title: "Stage 3", items: [] },
    { id: 4, title: "Stage 4", items: [] },
  ]);

  const [flowOnePipeline, setFlowOnePipeline] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [editingText, setEditingText] = useState("");

  const addItem = (stageId, itemTitle) => {
    setStages((prevStages) =>
      prevStages.map((stage) =>
        stage.id === stageId
          ? {
              ...stage,
              items: [...stage.items, { id: Date.now(), title: itemTitle }],
            }
          : stage
      )
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Check if the item is dropped in a different stage
    if (source.droppableId !== destination.droppableId) {
      const sourceStage = stages.find(
        (stage) => stage.id === parseInt(source.droppableId)
      );
      const destStage = stages.find(
        (stage) => stage.id === parseInt(destination.droppableId)
      );

      // Check if the source and destination stages are valid
      if (sourceStage && destStage) {
        const movedItem = sourceStage.items.find(
          (item) => item.id === parseInt(result.draggableId)
        );

        // Check if the moved item exists in the source stage
        if (movedItem) {
          setStages((prevStages) =>
            prevStages.map((stage) =>
              stage.id === sourceStage.id
                ? {
                    ...stage,
                    items: stage.items.filter(
                      (item) => item.id !== parseInt(result.draggableId)
                    ),
                  }
                : stage.id === destStage.id
                ? {
                    ...stage,
                    items: [...stage.items, movedItem],
                  }
                : stage
            )
          );
          setEditingItem(null); // Clear editing state when moving items between stages
          setEditingText("");
        }
      }
    } else {
      // Reorder items within the same stage
      const stage = stages.find(
        (stage) => stage.id === parseInt(source.droppableId)
      );

      // Check if the stage is valid
      if (stage) {
        const updatedItems = [...stage.items];
        const movedItem = updatedItems.splice(source.index, 1)[0];
        updatedItems.splice(destination.index, 0, movedItem);

        setStages((prevStages) =>
          prevStages.map((s) =>
            s.id === stage.id ? { ...s, items: updatedItems } : s
          )
        );
      }
    }
  };

  const handleItemClick = (item) => {
    setEditingItem(item);
    setEditingText(item.title);
  };

  const handleSaveEdit = () => {
    setStages((prevStages) =>
      prevStages.map((stage) =>
        stage.items.some((item) => item.id === editingItem.id)
          ? {
              ...stage,
              items: stage.items.map((item) =>
                item.id === editingItem.id
                  ? { ...item, title: editingText }
                  : item
              ),
            }
          : stage
      )
    );
    setEditingItem(null);
    setEditingText("");
  };

  const handleCancelEdit = () => {
    setEditingItem(null);
    setEditingText("");
  };

  //functions

  async function getFlowForOnePipeline(pipelineID) {
    try {
      let response = await axios.get(
        `https://api.employment.group/api/getstage?pipelineid=${pipelineID}`
      );

      if (response?.data?.data?.length > 0) {
        setFlowOnePipeline(response?.data?.data);
      }

      console.log(response, "Get Flow For One Pipeline response");
    } catch (error) {
      console.log(error, "Get Flow For One Pipeline error");
    }
  }

  //rendering

  useEffect(() => {
    if (stateValue?.data?.pipelineid) {
      getFlowForOnePipeline(stateValue?.data?.pipelineid);
    }
  }, []);

  return (
    <div className={classNames.kanbanBoard}>
      {Array.isArray(flowOnePipeline) && flowOnePipeline?.length > 0
        ? flowOnePipeline?.map((eachItem, index) => {
            return (
              <div
                className={classNames.kanbanStage}
                key={eachItem?.name + index}
              >
                <div className={classNames.stageHeader}>
                  <h3>{eachItem?.name}</h3>
                  <div
                    className={classNames.addItemBtn}
                    onClick={() => {
                      // setState(false);
                      setLocalSidebar("addnewstep");
                    }}
                  >
                    +
                  </div>
                </div>
                <div className={classNames.stageItems}>
                  {/* <div className={classNames.kanbamItem}>
                    {editingItem === item ? (
              <>
                <textarea
                  placeholder="Enter card value.."
                  value={editingText === "New Item" ? "" : editingText}
                  onChange={(e) => setEditingText(e.target.value)}
                ></textarea>
                <div className={classNames.editBtns}>
                  <button onClick={handleSaveEdit}>Save</button>
                  <button onClick={handleCancelEdit}>Cancel</button>
                </div>
              </>
            ) : (
              <textarea
                className={classNames.contentTextArea}
                placeholder="Enter card value.."
                value={editingText}
                style={{ pointerEvents: "none" }}
              ></textarea>
            )}
                  </div> */}
                  {Array.isArray(eachItem?.stepdetails) &&
                  eachItem?.stepdetails?.length > 0
                    ? eachItem?.stepdetails?.map((eachStep, i) => {
                        return (
                          <div
                            className={classNames.kanbanItem}
                            key={eachStep?.name + i}
                          >
                            <div className={classNames.title}>
                              {eachStep?.name}
                            </div>
                            {Array.isArray(eachStep.AutomateTaskDetails) &&
                            eachStep.AutomateTaskDetails?.length > 0
                              ? eachStep.AutomateTaskDetails?.map(
                                  (eachTask, ii) => {
                                    return (
                                      <div
                                        className={classNames.subItem}
                                        key={eachTask?._id}
                                      >
                                        {eachTask?.automationitem}
                                      </div>
                                    );
                                  }
                                )
                              : ""}
                            {Array.isArray(eachStep.ManualTaskDetails) &&
                            eachStep.ManualTaskDetails?.length > 0
                              ? eachStep.ManualTaskDetails?.map(
                                  (eachTask, ii) => {
                                    return (
                                      <div
                                        className={classNames.subItem}
                                        key={eachTask?._id}
                                      >
                                        {eachTask?.name}
                                      </div>
                                    );
                                  }
                                )
                              : ""}
                            <div className={classNames.subAddBtns}>
                              <div
                                onClick={() => {
                                  // setState(false);
                                  setLocalSidebar("addnewautomatedtask");
                                }}
                              >
                                <span>+</span> Automated Task
                              </div>
                              <div
                                onClick={() => {
                                  // setState(false);
                                  setLocalSidebar("addnewmanualtasks");
                                }}
                              >
                                <span>+</span> Manual Task
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
};

export default KanbanBoard;

//  <DragDropContext onDragEnd={onDragEnd}>
//    <div className="kanban-board">
//      {stages.map((stage) => (
//        <div key={stage.id} className="kanban-stage">
//          <div className="stage-header">
//            <h3>{stage.title}</h3>
//            <button
//              className="add-item-btn"
//              onClick={() => addItem(stage.id, "New Item")}
//            >
//              +
//            </button>
//          </div>
//          <Droppable droppableId={stage.id.toString()}>
//            {(provided) => (
//              <div
//                className="stage-items"
//                {...provided.droppableProps}
//                ref={provided.innerRef}
//              >
//                {stage.items.map((item, index) => (
//                  <Draggable
//                    key={item.id}
//                    draggableId={item.id.toString()}
//                    index={index}
//                  >
//                    {(provided) => (
//                      <div
//                        className="kanban-item"
//                        {...provided.dragHandleProps}
//                        {...provided.draggableProps}
//                        ref={provided.innerRef}
//                        onClick={() => handleItemClick(item)}
//                      >
//                        {editingItem === item ? (
//                          <>
//                            <textarea
//                              placeholder="Enter card value.."
//                              value={
//                                editingText === "New Item" ? "" : editingText
//                              }
//                              onChange={(e) => setEditingText(e.target.value)}
//                            ></textarea>
//                            <div className="edit-buttons">
//                              <button onClick={handleSaveEdit}>Save</button>
//                              <button onClick={handleCancelEdit}>Cancel</button>
//                            </div>
//                          </>
//                        ) : (
//                          <textarea
//                            className="contentTextArea"
//                            placeholder="Enter card value.."
//                            value={editingText}
//                            style={{ pointerEvents: "none" }}
//                          ></textarea>
//                        )}
//                      </div>
//                    )}
//                  </Draggable>
//                ))}
//                {provided.placeholder}
//              </div>
//            )}
//          </Droppable>
//        </div>
//      ))}
//    </div>
//  </DragDropContext>;
