import React from "react";
import "./calendar.scss";
import Skeleton from "react-loading-skeleton";

import leftArrow from "./calAssets/arrowLeft.svg";
import rightArrow from "./calAssets/arrowRight.svg";
import { useContext } from "react";
import { GlobalContext } from "../../../context/Globalcontext";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import "react-loading-skeleton/dist/skeleton.css";

import arrowDown from "./calAssets/dropdownArrow.svg";
import noAvatar from "./calAssets/noAvatar.svg";

const BookingCalendar = () => {
  const { collapse, email } = useContext(GlobalContext);

  const [allMonths, setAllMonths] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "Jun",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const [allDays, setAllDays] = useState([
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ]);
  const [thirtyDays, setThirtyDays] = useState([1, 3, 5, 8, 10]);

  const [monthIndex, setMonthIndex] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [firstDay, setFirstDay] = useState(0);

  const [calendarData, setCalendarData] = useState([]);
  const [previousCalandarData, setPreviousCalandarData] = useState([]);
  const [nextCalandarData, setNextCalandarData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [openFilter, setOpenFilter] = useState(false);
  const [filterType, setFilterType] = useState(null);

  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedDayData, setSelectedDayData] = useState(null);
  const [showOneDayView, setShowOneDayView] = useState(false);

  const [allCalData, setAllCalData] = useState([]);

  const filterMenu = [
    {
      filterName: "By date range",
      filterType: "date",
    },
    {
      filterName: "By specialist",
      filterType: "specialist",
    },
    {
      filterName: "By location",
      filterType: "location",
    },
    {
      filterName: "By service",
      filterType: "service",
    },
    {
      filterName: "By status",
      filterType: "status",
    },
    {
      filterName: "By client",
      filterType: "client",
    },
  ];

  useEffect(() => {
    setOpenFilter(false);
  }, [filterType]);

  const monthForward = () => {
    if (monthIndex !== 11) {
      setMonthIndex(monthIndex + 1);
    } else {
      setYear(year + 1);
      setMonthIndex(0);
    }
  };

  const monthBackword = () => {
    if (monthIndex !== 0) {
      setMonthIndex(monthIndex - 1);
    } else {
      setYear(year - 1);
      setMonthIndex(11);
    }
  };

  const dateForward = () => {
    const dateParts = selectedDay.dateNumber.split("/");

    // Extract individual components
    let month = parseInt(dateParts[0]);
    let day = parseInt(dateParts[1]);
    let year = parseInt(dateParts[2]);

    // Calculate the last day of the current month
    const lastDayOfMonth = new Date(year, month, 0).getDate();

    // Increment the day
    day++;

    // Check if the day exceeds the last day of the current month
    if (day > lastDayOfMonth) {
      // If day exceeds, move to the first day of the next month
      day = 1;
      month++;

      // If month exceeds 12, move to January of the next year
      if (month > 12) {
        month = 1;
        year++;
      }
    }

    const formattedMonth = month < 10 ? "0" + month : month;
    const formattedDay = day < 10 ? "0" + day : day;
    const newDateNumber = formattedMonth + "/" + formattedDay + "/" + year;

    setSelectedDay({
      dateNumber: newDateNumber,
      day: "",
      date: day,
    });
  };
  const datebackword = () => {
    const dateParts = selectedDay.dateNumber.split("/");

    // Extract individual components
    let month = parseInt(dateParts[0]);
    let day = parseInt(dateParts[1]);
    let year = parseInt(dateParts[2]);

    // Decrement the day
    day--;

    // Check if the day is now 0
    if (day === 0) {
      // If day is 0, move to the last day of the previous month
      month--;

      // If month is now 0, move to December of the previous year
      if (month === 0) {
        month = 12;
        year--;
      }

      // Calculate the last day of the new month
      day = new Date(year, month, 0).getDate();
    }

    const formattedMonth = month < 10 ? "0" + month : month;
    const formattedDay = day < 10 ? "0" + day : day;
    const newDateNumber = formattedMonth + "/" + formattedDay + "/" + year;

    setSelectedDay({
      dateNumber: newDateNumber,
      day: "",
      date: day,
    });
  };

  const [isSpecialist, setIsSpecialist] = useState(null);
  useEffect(() => {
    axios
      .get(
        `https://api.employment.group/api/getspecialistdetail?email=${email}`
      )
      .then(({ data }) => {
        if (data.success) {
          setIsSpecialist(data?.data[0]?.specialistDetails[0]);
        } else {
          setIsSpecialist(false);
        }
      });
  }, []);

  useEffect(() => {
    const uname = JSON.parse(localStorage.getItem("orginzationdetail"));
    if (isSpecialist === false) {
      axios
        .get(
          `https://api.employment.group/api/getbooking?teamusername=${uname?.TeamUsername}`
        )
        .then((res) => {
          if (res.data.success) {
            setAllCalData(res.data.data);
          }
        });
    } else if (isSpecialist !== null && isSpecialist !== false) {
      axios
        .get(
          `https://api.employment.group/api/getbooking?specialistid=${isSpecialist?.specialistid}`
        )
        .then((res) => {
          if (res.data.success) {
            setAllCalData(res.data.data);
          }
        });
    }
  }, [isSpecialist]);

  useEffect(() => {
    // console.log(
    //   new Date(`${allMonths[monthIndex]} 1, ${year}`).getUTCDay(),
    //   `${allMonths[monthIndex]} 1, ${year}`,
    //   "jkbkbk"
    // );
    if (new Date(`${allMonths[monthIndex]} 1, ${year}`).getUTCDay() !== 6) {
      setFirstDay(new Date(`${allMonths[monthIndex]} 1, ${year}`).getUTCDay());
    } else {
      setFirstDay(-1);
    }
    getMonthDuration(monthIndex);
  }, [monthIndex]);

  const getMonthDuration = (selectedMonthIndex) => {
    // console.log(selectedMonthIndex, ":kjwbkwe");
    if (thirtyDays.find((o) => o === selectedMonthIndex)) {
      if (selectedMonthIndex !== 1) {
        return 30;
      } else {
        if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
          return 29;
        } else {
          return 28;
        }
      }
    } else {
      return 31;
    }
  };

  const multiApiCalData = () => {
    setLoading(true);
    setCalendarData([]);
    const uName = localStorage.getItem("profileUsername");
    const uname = JSON.parse(
      localStorage.getItem("orginzationdetail")
    )?.TeamUsername;

    if (isSpecialist === false) {
      let endpoints = [
        `https://api.employment.group/api/getbooking?teamusername=${uname}&startDate=${monthIndex > 9 ? monthIndex : `0${monthIndex}`}/20/${
          monthIndex === 0 ? year - 1 : year
        }&endDate=${monthIndex + 1 > 9 ? monthIndex + 1 : `0${monthIndex + 1}`}/01/${monthIndex === 0 ? year - 1 : year}`,
        `https://api.employment.group/api/getbooking?teamusername=${uname}&startDate=${
          monthIndex + 1 > 9 ? monthIndex + 1 : `0${monthIndex + 1}`
        }/01/${year}&endDate=${monthIndex + 2 > 9 ? monthIndex + 2 : `0${monthIndex + 2}`}/01/${year}`,
        `https://api.employment.group/api/getbooking?teamusername=${uname}&startDate=${
          monthIndex + 2 > 9 ? monthIndex + 2 : `0${monthIndex + 2}`
        }/01/${monthIndex === 11 ? year + 1 : year}&endDate=${
          monthIndex + 2 > 9 ? monthIndex + 2 : `0${monthIndex + 2}`
        }/10/${monthIndex === 11 ? year + 1 : year}`,
      ];

      axios
        .all(endpoints.map((endpoint) => axios.get(endpoint)))
        .then((res) => {
          console.log(res[0].data.data, "lwehflwhelfjhgwekrf");
          setPreviousCalandarData(res[0].data.data);
          setCalendarData(res[1].data.data);
          setNextCalandarData(res[2].data.data);
          setLoading(false);
        });
    } else if (isSpecialist !== null && isSpecialist !== false) {
      let endpoints = [
        `https://api.employment.group/api/getbooking?specialistid=${isSpecialist?.specialistid}&startDate=${monthIndex > 9 ? monthIndex : `0${monthIndex}`}/20/${
          monthIndex === 0 ? year - 1 : year
        }&endDate=${monthIndex + 1 > 9 ? monthIndex + 1 : `0${monthIndex + 1}`}/01/${monthIndex === 0 ? year - 1 : year}`,
        `https://api.employment.group/api/getbooking?specialistid=${isSpecialist?.specialistid}&startDate=${
          monthIndex + 1 > 9 ? monthIndex + 1 : `0${monthIndex + 1}`
        }/01/${year}&endDate=${monthIndex + 2 > 9 ? monthIndex + 2 : `0${monthIndex + 2}`}/01/${year}`,
        `https://api.employment.group/api/getbooking?specialistid=${isSpecialist?.specialistid}&startDate=${
          monthIndex + 2 > 9 ? monthIndex + 2 : `0${monthIndex + 2}`
        }/01/${monthIndex === 11 ? year + 1 : year}&endDate=${
          monthIndex + 2 > 9 ? monthIndex + 2 : `0${monthIndex + 2}`
        }/10/${monthIndex === 11 ? year + 1 : year}`,
      ];

      axios
        .all(endpoints.map((endpoint) => axios.get(endpoint)))
        .then((res) => {
          console.log(res[0].data.data, "lwehflwhelfjhgwekrf");
          setPreviousCalandarData(res[0].data.data);
          setCalendarData(res[1].data.data);
          setNextCalandarData(res[2].data.data);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    multiApiCalData();
  }, [monthIndex, email, isSpecialist]);

  const getPreviousData = (date) => {
    return previousCalandarData?.map((item) => {
      if (
        Number(item.dateOfBooking.split("/")[0]) === monthIndex &&
        Number(item.dateOfBooking.split("/")[1]) === Number(date) &&
        Number(item.dateOfBooking.split("/")[2]) ===
          Number(monthIndex === 0 ? year - 1 : year)
      ) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              border: "0.5px solid #C57E50",
              background: "rgba(197, 126, 80, 0.05)",
              marginBottom: "10px",
              height: "40px",
              borderRadius: "5px",
            }}
          >
            <div
              style={{
                width: "45%",
                borderRight: "0.5px solid #C57E50",
                display: "flex",
                padding: "0px 8px",
                alignItems: "center",
                fontSize: "1vh",
                fontWeight: 600,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {item?.timeOfBooking}
              </div>
            </div>
            <div
              style={{
                width: "55%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1vh",
                fontWeight: 600,
              }}
            >
              {/* {conditionalData(item)} */}
              {item?.clientdetails[0]?.name}
            </div>
          </div>
        );
      }
    });
  };

  const getData = (date) => {
    return calendarData?.map((item) => {
      if (
        Number(item.dateOfBooking.split("/")[0]) === monthIndex + 1 &&
        Number(item.dateOfBooking.split("/")[1]) === Number(date) &&
        Number(item.dateOfBooking.split("/")[2]) === Number(year)
      ) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              border: "0.5px solid #C57E50",
              background: "rgba(197, 126, 80, 0.05)",
              marginBottom: "10px",
              height: "40px",
              borderRadius: "5px",
            }}
          >
            <div
              style={{
                width: "45%",
                borderRight: "0.5px solid #C57E50",
                display: "flex",
                padding: "0px 8px",
                alignItems: "center",
                fontSize: "1vh",
                fontWeight: 600,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {item?.timeOfBooking}
              </div>
            </div>
            <div
              style={{
                width: "55%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1vh",
                fontWeight: 600,
              }}
            >
              {/* {conditionalData(item)} */}
              {item?.clientdetails[0]?.name}
            </div>
          </div>
        );
      }
    });
  };

  const getNextData = (date) => {
    return nextCalandarData?.map((item) => {
      if (
        Number(item.dateOfBooking.split("/")[0]) === monthIndex + 2 &&
        Number(item.dateOfBooking.split("/")[1]) === Number(date) &&
        Number(item.dateOfBooking.split("/")[2]) ===
          Number(monthIndex === 11 ? year + 1 : year)
      ) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              border: "0.5px solid #C57E50",
              background: "rgba(197, 126, 80, 0.05)",
              marginBottom: "10px",
              height: "40px",
              borderRadius: "5px",
            }}
          >
            <div
              style={{
                width: "45%",
                borderRight: "0.5px solid #C57E50",
                display: "flex",
                padding: "0px 8px",
                alignItems: "center",
                fontSize: "1vh",
                fontWeight: 600,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {item?.timeOfBooking}
              </div>
            </div>
            <div
              style={{
                width: "55%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1vh",
                fontWeight: 600,
              }}
            >
              {/* {conditionalData(item)} */}
              {item?.clientdetails[0]?.name}
            </div>
          </div>
        );
      }
    });
  };

  const conditionalData = (data) => {
    switch (filterType) {
      case "Net Spread":
        return data.coin === "USDT"
          ? data.spreadEarned.toFixed(2)
          : data.spreadEarned.toFixed(4);
      case "Bond Holders":
        return data.customersCount;
      case "Capital Raised":
        return data.coin === "USDT"
          ? data.investment_amount.toFixed(2)
          : data.investment_amount.toFixed(4);
      case "Interest Earned":
        return data.coin === "USDT"
          ? data.interestEarned_amount.toFixed(2)
          : data.interestEarned_amount.toFixed(4);
      case "Interest Paid":
        return data.coin === "USDT"
          ? data.interestPaid_amount.toFixed(2)
          : data.interestPaid_amount.toFixed(4);
      case "Custom Comp":
        return data.coin === "USDT"
          ? data.customCom_amount.toFixed(2)
          : data.customCom_amount.toFixed(4);
      case "Bonds Sold":
        return data.bondPurchases;
      default:
        break;
    }
  };

  const handleDaySelection = (index) => {
    console.log(index, "ljkwebkjwekfjw");
    const prevDate =
      getMonthDuration(monthIndex - 1) - firstDay + index <= 31
        ? getMonthDuration(monthIndex - 1) - firstDay + index
        : 0;
    const currentDate =
      getMonthDuration(monthIndex) + firstDay >= index ? index - firstDay : 0;
    const nextDate =
      index > getMonthDuration(monthIndex) + firstDay
        ? index - getMonthDuration(monthIndex) - firstDay
        : 0;

    if (prevDate !== 0) {
      setSelectedDay({
        dateNumber: `${monthIndex === 0 ? 12 : monthIndex}/${prevDate}/${
          monthIndex === 0 ? year - 1 : year
        }`,
        day: ``,
        date: prevDate,
      });
    } else if (currentDate !== 0) {
      setSelectedDay({
        dateNumber: `${monthIndex + 1}/${currentDate}/${year}`,
        day: ``,
        date: currentDate,
      });
    } else if (nextDate !== 0) {
      setSelectedDay({
        dateNumber: `${monthIndex === 11 ? 1 : monthIndex + 2}/${nextDate}/${
          monthIndex === 11 ? year + 1 : year
        }`,
        day: ``,
        date: nextDate,
      });
    }
  };

  //Get One Day Data

  useEffect(() => {
    console.log(selectedDay, "lkwhefkwhefkhwjefk");
    if (selectedDay) {
      let dateNumber = selectedDay.dateNumber;
      let parts = dateNumber.split("/");
      parts[0] = parts[0].padStart(2, "0");
      parts[1] = parts[1].padStart(2, "0");
      selectedDay.dateNumber = parts.join("/");

      const filteredData = allCalData.filter(
        (item) => item.dateOfBooking === selectedDay.dateNumber
      );

      // const foundData = calendarData.find(
      //   (o) => o.dateOfBooking === selectedDay.dateNumber
      // );
      // console.log(foundData, selectedDay, "jhwkecwecf");
      if (filteredData.length > 0) {
        setSelectedDayData(filteredData);
        setShowOneDayView(true);
      } else {
        console.log("nodata", "jhwkecwecf");
        setSelectedDayData([]);
        // setShowOneDayView(false);
      }
    }
  }, [selectedDay]);

  const conditionalStatus = (item) => {
    if (item?.bookingStatus === "booked") {
      return <div style={{ background: "#34A853" }}>Booked</div>;
    } else if (item?.bookingStatus === "canceled") {
      return <div style={{ background: "#FF0000" }}>Cancelled</div>;
    } else if (item?.bookingStatus === "arrived") {
      return <div style={{ background: "#C57E50" }}>Arrived</div>;
    } else if (item?.bookingStatus === "pre-assessed") {
      return <div style={{ background: "#0052B4" }}>Pre-Assessed</div>;
    } else if (item?.bookingStatus === "consulting") {
      return <div style={{ background: "#FBBC05" }}>Consulting</div>;
    } else if (item?.bookingStatus === "completed") {
      return <div style={{ background: "#000000" }}>Completed</div>;
    }
  };

  return (
    <>
      <div style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "60px",
            padding: "0px 27px",
            background: "whitesmoke",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {showOneDayView ? (
              <div
                className="monthName"
                style={{ width: "250px" }}
                onClick={(e) => {
                  setShowOneDayView(false);
                  setSelectedDay(null);
                }}
              >
                {new Date(selectedDay.dateNumber).toDateString()}
              </div>
            ) : (
              <div className="monthName">
                {allMonths[monthIndex]} {year}
              </div>
            )}

            <div>
              <img
                className="arrowButtons"
                onClick={showOneDayView ? datebackword : monthBackword}
                src={leftArrow}
                alt=""
              />
              <img
                className="arrowButtons"
                onClick={showOneDayView ? dateForward : monthForward}
                src={rightArrow}
                alt=""
                style={{ marginLeft: "6px" }}
              />
            </div>
          </div>

          {openFilter ? (
            <>
              <div
                className="filterClosed"
                onClick={(e) => setOpenFilter(!openFilter)}
              >
                <div>
                  {filterType?.filterName
                    ? filterType?.filterName
                    : "Add Filter"}
                </div>
                <img src={arrowDown} alt="" />
              </div>
              <div className="filterOpen">
                {filterMenu.map((item, index) => {
                  if (showOneDayView) {
                    if (index > 0) {
                      return (
                        <div
                          onClick={(e) => setFilterType(item)}
                          style={{
                            fontWeight:
                              filterType?.filterName === item.filterName
                                ? 700
                                : "",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>{item.filterName}</div>
                          {/* <img src={arrowDown} alt="" /> */}
                        </div>
                      );
                    }
                  } else {
                    return (
                      <div
                        onClick={(e) => setFilterType(item)}
                        style={{
                          fontWeight:
                            filterType?.filterName === item.filterName
                              ? 700
                              : "",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>{item.filterName}</div>
                        {/* <img src={arrowDown} alt="" /> */}
                      </div>
                    );
                  }
                })}
              </div>
            </>
          ) : (
            <div className="filterClosed" onClick={(e) => setOpenFilter(true)}>
              <div>{filterType ? filterType?.filterName : "Add Filter"}</div>
              <img
                src={arrowDown}
                alt=""
                style={{ transform: "rotate(180deg)" }}
              />
            </div>
          )}
        </div>
        {!showOneDayView ? (
          <div className="calendarBody">
            {Array(35)
              .fill("")
              .map((item, index) => {
                return (
                  <div
                    style={{
                      textAlign: "center",
                      opacity:
                        index > firstDay &&
                        index <= getMonthDuration(monthIndex) + firstDay
                          ? 1
                          : 0.4,
                    }}
                    onClick={(e) => handleDaySelection(index)}
                  >
                    <div
                      style={{
                        height: (window.innerHeight - 183) / 5,
                        border: "0.5px solid #E7E7E7",
                        borderWidth: "0px 0.5px 0.5px 0px",
                      }}
                    >
                      {/* days */}
                      <div
                        style={{
                          fontSize: "10px",
                          fontWeight: 400,
                          paddingTop: "10px",
                        }}
                      >
                        {index < 7 ? <div>{allDays[index]}</div> : ""}
                      </div>
                      {/* Previous Month Dates */}
                      {index <= firstDay ? (
                        <>
                          <div
                            style={{
                              fontSize: "10px",
                              fontWeight: 700,
                            }}
                          >
                            {getMonthDuration(monthIndex - 1) -
                              firstDay +
                              index}
                          </div>
                          <div
                            style={{
                              padding: "15px 18px",
                              overflowY: "scroll",
                              height:
                                index < 7
                                  ? (window.innerHeight - 140) / 8
                                  : (window.innerHeight - 140) / 9,
                            }}
                          >
                            {loading ? (
                              <>
                                <Skeleton className="name" width="100%" />
                                <Skeleton className="email" width="100%" />
                              </>
                            ) : (
                              getPreviousData(
                                getMonthDuration(monthIndex - 1) -
                                  firstDay +
                                  index
                              )
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {/* Current Month Dates */}
                      {index > firstDay &&
                      getMonthDuration(monthIndex) + firstDay >= index ? (
                        <>
                          <div
                            style={{
                              // paddingTop: "5px",
                              fontSize: "10px",
                              fontWeight: 700,
                            }}
                          >
                            {index - firstDay}
                          </div>
                          <div
                            style={{
                              padding: "15px 18px",
                              overflowY: "scroll",
                              height:
                                index < 7
                                  ? (window.innerHeight - 140) / 8
                                  : (window.innerHeight - 140) / 7,
                            }}
                          >
                            {loading ? (
                              <>
                                <Skeleton className="name" width="100%" />
                                <Skeleton className="email" width="100%" />
                              </>
                            ) : (
                              getData(index - firstDay)
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {/* Next Month Dates */}
                      {index > getMonthDuration(monthIndex) + firstDay ? (
                        <>
                          <div
                            style={{
                              paddingTop: "5px",
                              fontSize: "10px",
                              fontWeight: 700,
                            }}
                          >
                            {index - getMonthDuration(monthIndex) - firstDay}
                          </div>
                          <div
                            style={{
                              padding: "15px 18px",
                              overflowY: "scroll",
                              height: (window.innerHeight - 140) / 7,
                            }}
                          >
                            {loading ? (
                              <>
                                <Skeleton className="name" width="100%" />
                                <Skeleton className="email" width="100%" />
                              </>
                            ) : (
                              getNextData(
                                index - getMonthDuration(monthIndex) - firstDay
                              )
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div
            className="singeDayBody"
            style={
              {
                // width: window.innerWidth - 365,
                // height: window.innerHeight - 182,
              }
            }
          >
            {selectedDayData?.map((item) => (
              <div className="clientCard">
                <div className="col1">
                  <div>{item?.dateOfBooking}</div>
                  <div>{item?.timeOfBooking}</div>
                </div>
                <div className="col3">
                  <div className="cardAvatar">
                    <img src={noAvatar} alt="" className="cardAvatar" />
                  </div>
                  <div>
                    <div className="title">{item?.clientdetails[0]?.name}</div>
                    <div className="subTitle">{item?.clientId}</div>
                  </div>
                </div>

                <div className="col3">
                  <div className="cardAvatar">
                    <img src={noAvatar} alt="" className="cardAvatar" />
                  </div>
                  <div>
                    <div className="title">
                      {item?.specialistDetails[0]?.PlayerDetails[0]?.name}
                    </div>
                    <div className="subTitle">{item?.specialistid}</div>
                  </div>
                </div>
                <div className="col3">
                  <div className="cardAvatar">
                    <img src={noAvatar} alt="" className="cardAvatar" />
                  </div>
                  <div>
                    <div className="title">{item?.servicedetails[0]?.name}</div>
                    <div className="subTitle">{item?.serviceId}</div>
                  </div>
                </div>
                <div className="col3">
                  <div>
                    <div className="title">
                      {item?.locationDetails[0]?.name}
                    </div>
                    <div className="subTitle">{item?.locationId}</div>
                  </div>
                </div>
                <div className="col3">
                  <div>
                    <div className="title">
                      {item?.servicedetails[0]?.duration} Hours
                    </div>
                    <div className="subTitle">
                      {item?.servicedetails[0]?.cost
                        ? item?.servicedetails[0]?.cost
                        : 0}{" "}
                      INR
                    </div>
                  </div>
                </div>
                <div className="col7">
                  {conditionalStatus(item)}
                  <div>Edit</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default BookingCalendar;
