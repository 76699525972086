import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./Wedge.module.scss";
import Drawer from "react-modern-drawer";
// import './wedgepage.scss'
//assets
import { ReactComponent as FilterIcon } from "../../assets/images/filter.svg";

import Editingpage from "./EditingPage/EditingPage";
import {
  CopyBtn,
  CustomCheckbox,
  EachCustomDropdown,
  EachCustomImageUpload,
  EachCustomImageUploadcustom,
  EachCustomSelectMultiple,
  EachInputCustom,
  EachCustomTextarea,
  OwnEachCustomDropdown,
  EachInput,
} from "../../components/Custom";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import { ReactComponent as SortArrows } from "../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/Globalcontext";
import { useNetworkRequest } from "../../hooks/networkRequest";
import { deleteData, getData } from "../../assets/functions/apiCalls";
import axios from "axios";
import { toastify } from "../../assets/functions/toastify";
import { filterDetails } from "../../assets/functions";
import { APP_CODE } from "../../assets/data/constant";

const Organization = () => {
  const {
    email,
    actionBtns,
    setActionBtns,
    globalSearchQuery,
    dropDowndownvalue,
    editingToggle,
    seteditingToggle,
  } = useContext(GlobalContext);
  const [checkBoxStage, setCheckBoxStage] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [wedge, setwedge] = useState([]);
  const [consulation, setconsulation] = useState({
    constultandName: [],
    constultandid: [],
    locationid: "",
    locationName: "",
    spaceid: "",
    spaceName: "",
    name: "",
    description: "",
    coverPhoto: "",
    duration: "",

    cost: "",
  });
  console.log("0000", consulation);

  useEffect(() => {
    tablefucntion();
  }, [dropDowndownvalue]);
  const [locationdata, setlocationdata] = useState({});

  const tablefucntion = () => {
    axios
      // .get(`https://api.employment.group/api/getwidget`)
      .get(
        `https://api.employment.group/api/getwidget?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
      )
      .then((res) => {
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
        setwedge(res?.data?.data);
      });
  };

  const [player, setplayer] = useState([]);

  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
  const monthNames = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  console.log("cxc66666zxczxc", player);
  const [active, setactive] = useState("Pre Assessment Form");

  const [page, setpage] = useState("main");
  const [getsesion, setgetsesion] = useState({});
  // useEffect(() => {
  //     setpage("second")
  // }, [])

  // const getclientid = (e) => {

  //     axios
  //         .get(`https://api.employment.group/api/getconsultwedges?consultationwedgeid=${e}`)
  //         .then((res) => {
  //             //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))

  //             setgetsesion(res?.data?.data?.[0] || {})
  //             setpage("second")

  //         });
  // }

  const [formName, setFormName] = useState("");
  const [questions, setQuestions] = useState([
    {
      name: "",
      answerType: "",
      isMandatory: null,
    },
  ]);
  const [isOpen, setIsOpen] = useState(null);
  const [formErr, setFormErr] = useState(null);
  const [allSpecialist, setAllSpecialist] = useState([]);
  const [selectedSpecialist, setSelectedSpecialist] = useState(null);
  const history = useNavigate()
  useEffect(() => {
    const uname = JSON.parse(
      localStorage.getItem("orginzationdetail")
    )?.TeamUsername;
    axios
      .get(
        `https://api.employment.group/api/getspecialist?teamusername=${uname}`
      )
      .then(({ data }) => {
        if (data.success) {
          setAllSpecialist(data.data);
        }
      });
  }, []);

  // State for managing dropdown option input
  const [dropdownOption, setDropdownOption] = useState("");

  // State for managing dropdown open/close
  // const [isOpen, setIsOpen] = useState(null);

  // Function to handle changes in question fields
  const handleQuestionChange = (index, event) => {
    const { name, value } = event.target;
    const updatedQuestions = [...questions];
    if (name === "answerType" && value === "Dropdown") {
      updatedQuestions[index] = {
        ...updatedQuestions[index],
        [name]: value,
        dropdownOptions: [], // Initialize empty array for dropdown options
      };
    } else {
      updatedQuestions[index] = {
        ...updatedQuestions[index],
        [name]: value,
      };
    }
    setQuestions(updatedQuestions);
  };

  // Function to add dropdown options
  const addDropdownOption = (questionIndex, optionValue) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].dropdownOptions.push(optionValue);
    setQuestions(updatedQuestions);
  };

  // Function to remove dropdown options
  const removeDropdownOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].dropdownOptions.splice(optionIndex, 1);
    setQuestions(updatedQuestions);
  };

  // Function to add a new question
  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        name: "",
        answerType: "",
        isMandatory: null,
      },
    ]);
  };

  // Function to remove a question
  const removeQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, event) => {
    const { value } = event.target;
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].dropdownOptions[optionIndex] = value;
    setQuestions(updatedQuestions);
  };

  // Function to add a new option
  const addOption = (questionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].dropdownOptions.push("");
    setQuestions(updatedQuestions);
  };

  // Function to remove an option
  const removeOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].dropdownOptions.splice(optionIndex, 1);
    setQuestions(updatedQuestions);
  };

  const handleSubmit = () => {
    console.log(
      {
        specialistid: selectedSpecialist?.speicalistId,
        formname: formName?.name,
        questions: questions,
      },
      "kjhkhkjhkjhkj"
    );
    axios
      .post(`https://api.employment.group/api/addwidget`, {
        specialistid: selectedSpecialist?.speicalistId,
        name: formName?.name,
        questions: questions,
      })
      .then(({ data }) => {
        if (data.status) {
          setCheckBoxStage("");
          setFormName("");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          setQuestions([
            {
              name: "",
              answerType: "",
              isMandatory: null,
            },
          ]);
          setFormErr(null);
        } else {
          setFormErr(data.message);
        }
      });
  };

  let loc = window.location.pathname;
  let finallo = loc?.split("/");

  console.log("cxc66666zxczxc", player);
  const [wediginfo, setwediginfo] = useState("")
  return (
    <>
      {editingToggle ? (
        <Editingpage
          staffitem={wediginfo}
        />
      ) : (
        <div className="global-dashboard">
          <div className="label-box-header">{finallo?.[3]}</div>
          <div className="top-section-golbal">
            <div className="left-side">
              <div className="label-box">
                <svg
                  style={{
                    marginRight: "10px",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M12.8445 8.07626L10.9486 6.58668C10.8262 6.4908 10.6594 6.47293 10.5202 6.54118C10.3804 6.60888 10.2916 6.7508 10.2916 6.90626V7.90292C8.75704 8.16184 7.58325 9.49651 7.58325 11.1042V12.4583C7.58325 12.7573 7.82592 13 8.12492 13C8.42392 13 8.66658 12.7573 8.66658 12.4583V11.1042C8.66658 10.0978 9.35992 9.25655 10.2916 9.01442V9.88542C10.2916 10.0409 10.3804 10.1828 10.5202 10.2505C10.5765 10.2781 10.6372 10.2917 10.6978 10.2917C10.7872 10.2917 10.876 10.2624 10.9486 10.205L12.8445 8.71542C12.943 8.63851 12.9999 8.52042 12.9999 8.39584C12.9999 8.27126 12.943 8.15317 12.8445 8.07626Z"
                    fill="#5A5656"
                  />
                  <path
                    d="M9.74999 0H1.625C0.731249 0 0 0.731249 0 1.625V8.66666C0 9.5604 0.731249 10.2917 1.625 10.2917H6.58124C6.65166 9.91249 6.77082 9.54957 6.93874 9.20832H1.625C1.32708 9.20832 1.08333 8.96457 1.08333 8.66666V2.70833H10.2917V5.47624C10.6492 5.37333 11.0392 5.41124 11.375 5.57916V1.625C11.375 0.731249 10.6437 0 9.74999 0V0Z"
                    fill="#5A5656"
                  />
                </svg>
                Export
              </div>

              <>
                <div className="label-box">
                  <svg
                    style={{
                      marginRight: "10px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                  >
                    <path
                      d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                      fill="#5A5656"
                    />
                  </svg>
                  Add Filter
                </div>
              </>
            </div>

            <div className="right-side">
              <div className="shortBy">Sort By</div>
              <div className="dropdownlist">
                Newest
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="6"
                  viewBox="0 0 7 6"
                  fill="none"
                >
                  <path
                    d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                    fill="#5A5656"
                  />
                </svg>
              </div>
              <div className="addLabel" onClick={() => setCheckBoxStage("add")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                    fill="white"
                  />
                </svg>
                Add New
              </div>
              <Drawer
                size={450}
                // duration={500}
                open={checkBoxStage}
                onClose={() => setCheckBoxStage("")}
                direction="right"
                className="bla bla bla"
                overlayColor="#FFF"
                overlayOpacity={0}
              >
                {checkBoxStage == "add" && (
                  <div className="drowpside-right">
                    {/* <div
                  className={classNames.closeBtn}
                  onClick={() => {
                    setCheckBoxStage("");
                  }}
                >
                  X
                </div> */}
                    <div
                      className={classNames.eachFilterItem}
                      style={{
                        fontWeight: "500",
                        pointerEvents: "none",
                        paddingLeft: "0",
                        marginBottom: "1.5rem",
                        paddingTop: "3rem",
                      }}
                    >
                      Add New Widget Form Template
                    </div>
                    <EachCustomDropdown
                      dropdown={allSpecialist}
                      name="specialistList"
                      title="Select specialist"
                      stateValue={selectedSpecialist}
                      setState={setSelectedSpecialist}
                      topTitle="true"
                    />
                    <EachInput
                      title="What is the name of this form?"
                      name="name"
                      placeholder="Name..."
                      stateValue={formName}
                      setState={setFormName}
                      topTitle="true"
                    />

                    {questions.map((question, index) => (
                      <div
                        key={index}
                        style={{
                          paddingBottom: "10px",
                          //   borderBottom: "0.5px solid #c57e50",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          className={classNames.topTitle}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>Question {index + 1}</div>
                          {index !== 0 && (
                            <IoCloseCircleOutline
                              className={classNames.closeBtn}
                              onClick={(e) => removeQuestion(index)}
                            />
                          )}
                        </div>
                        <input
                          className={classNames.inputContainer}
                          type="text"
                          name="name"
                          placeholder="Enter question"
                          value={question.name}
                          onChange={(e) => handleQuestionChange(index, e)}
                        />
                        <div
                          className={classNames.topTitle}
                          style={{ paddingTop: "2rem" }}
                        >
                          Select question type
                        </div>
                        <div
                          className={
                            isOpen === index && isOpen !== null
                              ? classNames.dropDownContainerOpen
                              : classNames.dropDownContainer
                          }
                          onClick={(e) =>
                            isOpen === null ? setIsOpen(index) : setIsOpen(null)
                          }
                        >
                          <div className={classNames.dropdownPlaceholder}>
                            <div style={{ fontSize: "0.8rem" }}>
                              {isOpen === index
                                ? "Click To Select"
                                : question?.answerType
                                  ? question?.answerType
                                  : "Click To Select"}
                            </div>
                            {isOpen === index ? (
                              <IoMdArrowDropup />
                            ) : (
                              <IoMdArrowDropdown />
                            )}
                          </div>
                          {isOpen === index && isOpen !== null && (
                            <div style={{ paddingTop: "10px" }}>
                              <div
                                className={classNames.dropItem}
                                onClick={(e) => {
                                  handleQuestionChange(index, {
                                    target: {
                                      name: "answerType",
                                      value: "String",
                                    },
                                  });
                                  setIsOpen(null);
                                }}
                              >
                                String
                              </div>
                              <div
                                className={classNames.dropItem}
                                onClick={(e) => {
                                  handleQuestionChange(index, {
                                    target: {
                                      name: "answerType",
                                      value: "Dropdown",
                                    },
                                  });
                                  setIsOpen(null);
                                }}
                              >
                                Dropdown
                              </div>
                            </div>
                          )}
                        </div>

                        {question.answerType === "Dropdown" && (
                          <div
                            style={{ marginLeft: "20px", paddingTop: "20px" }}
                          >
                            <div className={classNames.topTitle}>
                              Add dropdown options
                            </div>
                            {question.dropdownOptions.map(
                              (option, optionIndex) => (
                                <div
                                  key={optionIndex}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <input
                                    className={classNames.inputContainer}
                                    style={{ width: "90%" }}
                                    type="text"
                                    placeholder="Enter option"
                                    value={option}
                                    onChange={(e) =>
                                      handleOptionChange(index, optionIndex, e)
                                    }
                                  />
                                  <IoCloseCircleOutline
                                    style={{ width: "20px", height: "20px" }}
                                    // className={classNames.closeBtn}
                                    onClick={() =>
                                      removeOption(index, optionIndex)
                                    }
                                  />
                                </div>
                              )
                            )}
                            <button
                              className={classNames.addQBtn}
                              onClick={() => addOption(index)}
                            >
                              Add Option
                            </button>
                          </div>
                        )}

                        <div
                          className={classNames.topTitle}
                          style={{ paddingTop: "2rem" }}
                        >
                          Is this question mandatory
                        </div>
                        <div className={classNames.mandatoryContainer}>
                          <div
                            className={`${classNames.mandatoryCard} ${question.isMandatory === true ? classNames.mandatoryCardSelected : ""}`}
                            onClick={() =>
                              handleQuestionChange(index, {
                                target: { name: "isMandatory", value: true },
                              })
                            }
                          >
                            Yes
                          </div>
                          <div
                            className={`${classNames.mandatoryCard} ${question.isMandatory === false ? classNames.mandatoryCardSelected : ""}`}
                            onClick={() =>
                              handleQuestionChange(index, {
                                target: { name: "isMandatory", value: false },
                              })
                            }
                          >
                            No
                          </div>
                        </div>
                        {/* <select
                          name="isMandatory"
                          value={question.isMandatory}
                          onChange={(e) => handleQuestionChange(index, e)}
                        >
                          <option value={null}>Select mandatory</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select> */}
                      </div>
                    ))}
                    <button
                      onClick={addQuestion}
                      className={classNames.addQBtn}
                    >
                      Add Question
                    </button>

                    <button
                      className={
                        questions.every((question) =>
                          Object.values(question).every(
                            (value) => value !== "" && value !== null
                          )
                        ) &&
                          questions.every(
                            (question) =>
                              question.answerType !== "Dropdown" ||
                              (question.answerType === "Dropdown" &&
                                question.dropdownOptions.length > 0)
                          ) &&
                          formName
                          ? classNames.submitBtn
                          : classNames.submitBtnDisabled
                      }
                      onClick={
                        questions.every((question) =>
                          Object.values(question).every(
                            (value) => value !== "" && value !== null
                          )
                        ) &&
                          questions.every(
                            (question) =>
                              question.answerType !== "Dropdown" ||
                              (question.answerType === "Dropdown" &&
                                question.dropdownOptions.length > 0)
                          ) &&
                          formName
                          ? handleSubmit
                          : null
                      }
                    >
                      Submit
                    </button>
                    {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
                  </div>
                )}
              </Drawer>
            </div>
          </div>

          <div className="handle-table">
            <div className="g-table-sec">
              <div className="table-section">
                <table style={{ minWidth: "100%" }}>
                  <thead>
                    <tr
                      style={{
                        gridTemplateColumns: "min-content repeat(4, 1fr)",
                      }}
                    >
                      <th>
                        Name <SortArrows />
                      </th>

                      <th>
                        Questions
                        <SortArrows />
                      </th>
                      <th>
                        Specialist
                        <SortArrows />
                      </th>

                      <th>
                        Filled Out Forms
                        <SortArrows />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {wedge?.map((row, index) => {
                      let date = row?.createdAt?.split("-");
                      let finial = row?.createdAt?.split("-")?.[2]?.split("T");
                      return (
                        <tr
                          key={row?.brokerage_name + index}
                          style={{
                            gridTemplateColumns: "min-content repeat(5, 1fr)",
                            pointerEvents: sidebarOpen ? "none" : "",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            history(`/owner/forms/widgetForms/${row?.widgetid}`)
                            setwediginfo(row?.widgetid)

                          }
                          }
                        >
                          <td>
                            {/* {
                            row?.TeamDetails?.map(such => {
                                return (
                                    such?.PlayerDetails?.map(item => {
                                        return (
                                            <>
                                                <img className="icon"
                                                    style={{
                                                        width: "26px",
                                                        height: "26px",
                                                        borderRadius: "10rem",
                                                        marginRight: "12px"
                                                    }}
                                                    src={item?.ProfilePic} />

                                                {item?.name}
                                                <CopyBtn
                                                    copyText={item?.name
                                                    }
                                                />
                                            </>
                                        )
                                    })
                                )
                            })
                        } */}

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {/* <img className="icon"
                                                            style={{
                                                                width: "26px",
                                                                height: "26px",
                                                                borderRadius: "10rem",
                                                                marginRight: "12px"
                                                            }}
                                                            src={row?.clientdetails?.[0]?.ProfilePic} /> */}
                              <div>
                                <div>{row?.name}</div>
                                {/* <div>
                                                                {row?.consultationwedgeid}
                                                            </div> */}
                              </div>
                            </div>
                          </td>

                          <td>{row?.questions?.length}</td>
                          <td>{row?.specialistid}</td>

                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Organization;
