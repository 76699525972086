import React, { useEffect, useState } from "react";
import classNames from "./pipelinesubmenu.module.scss";
import "../../../assets/Scsss/Global.scss";
import "../../Booking/Dashboard/BookingDrawer.scss";

//assets

import { ReactComponent as SettingsIcon } from "../../../assets/images/icons/settings.svg";
import KanbanBoard from "./Flow";
import {
  EachCustomDropdown,
  EachCustomTextarea,
  EachInput,
} from "../../../components/Custom";
import axios from "axios";
import Drawer from "react-modern-drawer";

const PipelineSubmenu = ({
  stateValue,
  setState,
  localSidebar,
  setLocalSidebar,
  setLocalRefresh,
}) => {
  //constants
  const allSubMenu = ["Profile", "Flow", "Stages", "Change History"];

  const [selectedMenu, setSelectedMenu] = useState("Flow");

  //imported sidebar

  const allautomationItems = [
    "Send invoice",
    "Send proposal",
    "Send delivery doc",
    "Send form",
    "Create task",
    "Schedule meeting",
  ];

  const [formErr, setFormErr] = useState(null);
  const [allSteps, setAllSteps] = useState([]);
  const [checkBoxStage, setCheckBoxStage] = useState("");
  const [addNewManualTasks, setAddNewManualTasks] = useState({});
  const [addNewAutomaticTasks, setAddNewAutomaticTasks] = useState({});
  const [addNewStep, setAddNewStep] = useState({});
  const [kichenItem, setkichenItem] = useState({
    name: "",
    description: "",
  });
  const [allStages, setAllStages] = useState([]);

  function getAllStages() {
    axios
      .get(
        `https://api.employment.group/api/getstage?teamusername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      )
      .then((res) => {
        setAllStages(res?.data?.data);
      })
      .catch((error) => {
        console.log(error?.message, "Get all pipelines server error");
      });
  }

  function getAllSteps() {
    axios
      .get(
        `https://api.employment.group/api/getstagestep?teamusername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      )
      .then((res) => {
        setAllSteps(res?.data?.data);
      })
      .catch((error) => {
        console.log(error?.message, "Get all pipelines server error");
      });
  }

  const handleSubmitAutomatedTask = () => {
    axios
      .post(`https://api.employment.group/api/createautomatetask`, {
        teamusername: JSON.parse(localStorage?.getItem("orginzationdetail"))
          ?.TeamUsername,
        ...addNewAutomaticTasks,
      })

      .then(({ data }) => {
        if (data.status) {
          setCheckBoxStage("");
          setLocalRefresh((prev) => !prev);
          setkichenItem({});
          setFormErr(null);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setFormErr(data.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "Server error while adding new stage");
      });
  };

  const handleSubmitManualTasks = () => {
    axios
      .post(`https://api.employment.group/api/createmanualtask`, {
        teamusername: JSON.parse(localStorage?.getItem("orginzationdetail"))
          ?.TeamUsername,
        ...addNewManualTasks,
      })

      .then(({ data }) => {
        if (data.status) {
          setCheckBoxStage("");
          setLocalRefresh((prev) => !prev);
          setkichenItem({});
          setFormErr(null);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setFormErr(data.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "Server error while adding new stage");
      });
  };

  const handleStepSubmit = () => {
    axios
      .post(`https://api.employment.group/api/addstagestep`, {
        teamusername: JSON.parse(localStorage?.getItem("orginzationdetail"))
          ?.TeamUsername,
        ...addNewStep,
      })

      .then(({ data }) => {
        if (data.status) {
          setCheckBoxStage("");
          setLocalRefresh((prev) => !prev);
          setFormErr(null);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setFormErr(data.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "Server error while adding new step");
      });
  };

  //renderings

  useEffect(() => {
    getAllStages();
    getAllSteps();
  }, []);

  return (
    <div className={classNames.pipelineSubmenu}>
      <div className={classNames.mainContainer}>
        <div className={classNames.headerContainer}>
          <div className="profileTop">
            <div
              className={classNames.profileName}
              style={{
                paddingBottom: "0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                onClick={() => setState(false)}
              >
                <circle
                  cx="16.5"
                  cy="16.5"
                  r="16"
                  fill="white"
                  stroke="#E9EBF3"
                />
                <path
                  d="M10.4497 16.8516L16.171 22.535C16.2768 22.64 16.4253 22.7 16.5808 22.7H19.0393C19.5282 22.7 19.7821 22.1619 19.4491 21.8314L14.0779 16.5L19.4491 11.1686C19.7821 10.8381 19.5282 10.3 19.0393 10.3H16.5808C16.4253 10.3 16.2768 10.3596 16.171 10.465L10.4497 16.1484C10.2502 16.3467 10.2502 16.6533 10.4497 16.8516Z"
                  fill="url(#paint0_linear_1918_382)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1918_382"
                    x1="19.6001"
                    y1="16.5"
                    x2="10.3001"
                    y2="16.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#0B2447" />
                    <stop offset="0.311" stop-color="#0D264C" />
                    <stop offset="0.6768" stop-color="#122E5A" />
                    <stop offset="1" stop-color="#191F41" />
                  </linearGradient>
                </defs>
              </svg>

              <div style={{ marginLeft: "15px" }}>
                <span style={{ fontSize: "1.2rem", fontWeight: 550 }}>
                  {stateValue?.data?.name}
                </span>
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                  }}
                >
                  {stateValue?.data?.pipelineid}
                </div>
              </div>
            </div>

            <div className="deletedEditingButton">
              <SettingsIcon />
              Actions
            </div>
          </div>
        </div>
        <div className={classNames.contentContainer}>
          <div className="profiletab">
            {allSubMenu?.map((eachItem, index) => {
              return (
                <div
                  className="items"
                  style={{
                    background: selectedMenu === eachItem ? "#3f506a" : "",
                  }}
                  onClick={() => {
                    setSelectedMenu(eachItem);
                  }}
                >
                  {eachItem}
                </div>
              );
            })}
          </div>
          {selectedMenu === "Flow" && (
            <KanbanBoard
              setState={setState}
              stateValue={stateValue}
              setLocalSidebar={setLocalSidebar}
            />
          )}
          <Drawer
            size={450}
            // duration={500}
            open={localSidebar}
            onClose={() => setLocalSidebar("")}
            direction="right"
            className="bla bla bla"
            overlayColor="#FFF"
            overlayOpacity={0}
          >
            {localSidebar === "addnewstep" ? (
              <div className="drowpside-right">
                <div
                  style={{
                    position: "absolute",
                    right: "1rem",
                    top: "2.5rem",
                  }}
                  className={classNames.closeBtn}
                  onClick={() => {
                    setLocalSidebar("");
                  }}
                >
                  X
                </div>
                <div
                  className={classNames.eachFilterItem}
                  style={{
                    fontWeight: "500",
                    pointerEvents: "none",
                    paddingLeft: "0",
                    fontSize: "1.2rem",
                  }}
                >
                  Add new step
                </div>
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <EachInput
                    title="Basic Information"
                    name="name"
                    placeholder="Name..."
                    stateValue={addNewStep}
                    setState={setAddNewStep}
                    topTitle="true"
                  />
                </div>
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <EachCustomTextarea
                    name="description"
                    placeholder="Description..."
                    stateValue={addNewStep}
                    setState={setAddNewStep}
                  />
                </div>
                <div className={classNames.selectOne}>
                  <div
                    className={
                      addNewStep?.automove ? classNames.selectedOne : ""
                    }
                    onClick={() => {
                      setAddNewStep((prev) => {
                        return { ...prev, automove: true, manual: false };
                      });
                    }}
                  >
                    Automove
                  </div>
                  <div
                    className={addNewStep?.manual ? classNames.selectedOne : ""}
                    onClick={() => {
                      setAddNewStep((prev) => {
                        return { ...prev, automove: false, manual: true };
                      });
                    }}
                  >
                    Manual Move
                  </div>
                </div>
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <EachCustomDropdown
                    dropdown={allStages}
                    name="stageid"
                    title="Select stage"
                    stateValue={addNewStep}
                    setState={setAddNewStep}
                    topTitle="true"
                    type="obj"
                    mapVal={{
                      name: "name",
                      desc: "description",
                    }}
                    stateVal={"stageid"}
                  />
                  <EachInput
                    name="order"
                    placeholder="Order in stage..."
                    stateValue={addNewStep}
                    setState={setAddNewStep}
                  />
                </div>
                <button
                  className={classNames.submitBtn}
                  onClick={handleStepSubmit}
                >
                  Submit
                </button>
                {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
              </div>
            ) : localSidebar === "addnewautomatedtask" ? (
              <div className="drowpside-right">
                <div
                  style={{
                    position: "absolute",
                    right: "1rem",
                    top: "2.5rem",
                  }}
                  className={classNames.closeBtn}
                  onClick={() => {
                    setLocalSidebar("");
                    setCheckBoxStage("");
                  }}
                >
                  X
                </div>
                <div
                  className={classNames.eachFilterItem}
                  style={{
                    fontWeight: "500",
                    pointerEvents: "none",
                    paddingLeft: "0",
                    fontSize: "1.2rem",
                    marginBottom: "2rem",
                  }}
                >
                  Add new automated task
                </div>
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <div className={classNames.subTitle}>Basic Information</div>
                  <div className={classNames.selectOneGrid}>
                    {allautomationItems?.map((eachItem, index) => {
                      return (
                        <div
                          key={eachItem + index}
                          className={
                            addNewAutomaticTasks?.automationitem === eachItem
                              ? classNames.selectedOne
                              : ""
                          }
                          onClick={() => {
                            setAddNewAutomaticTasks((prev) => {
                              return {
                                ...prev,
                                automationitem: eachItem,
                              };
                            });
                          }}
                        >
                          {eachItem}
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <EachCustomDropdown
                    dropdown={allSteps}
                    name="stagestepid"
                    title="Step information"
                    stateValue={addNewAutomaticTasks}
                    setState={setAddNewAutomaticTasks}
                    topTitle="true"
                    type="obj"
                    mapVal={{
                      name: "name",
                      desc: "stagestepid",
                    }}
                    stateVal={"stagestepid"}
                  />
                  <EachInput
                    name="order"
                    placeholder="Order in step..."
                    stateValue={addNewAutomaticTasks}
                    setState={setAddNewAutomaticTasks}
                  />
                </div>
                <button
                  className={classNames.submitBtn}
                  onClick={handleSubmitAutomatedTask}
                >
                  Submit
                </button>
                {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
              </div>
            ) : localSidebar === "addnewmanualtasks" ? (
              <div className="drowpside-right">
                <div
                  style={{
                    position: "absolute",
                    right: "1rem",
                    top: "2.5rem",
                  }}
                  className={classNames.closeBtn}
                  onClick={() => {
                    setLocalSidebar("");
                    setCheckBoxStage("");
                  }}
                >
                  X
                </div>
                <div
                  className={classNames.eachFilterItem}
                  style={{
                    fontWeight: "500",
                    pointerEvents: "none",
                    paddingLeft: "0",
                    fontSize: "1.2rem",
                    marginBottom: "2rem",
                  }}
                >
                  Add new manual task
                </div>
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <EachInput
                    title="Basic Information"
                    name="name"
                    placeholder="Name..."
                    stateValue={addNewManualTasks}
                    setState={setAddNewManualTasks}
                    topTitle="true"
                  />
                </div>
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <EachCustomTextarea
                    name="description"
                    placeholder="Description..."
                    stateValue={addNewManualTasks}
                    setState={setAddNewManualTasks}
                  />
                </div>
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <EachCustomDropdown
                    dropdown={allSteps}
                    name="stagestepid"
                    title="Step information"
                    stateValue={addNewManualTasks}
                    setState={setAddNewManualTasks}
                    topTitle="true"
                    type="obj"
                    mapVal={{
                      name: "name",
                      desc: "stagestepid",
                    }}
                    stateVal={"stagestepid"}
                  />
                  <EachInput
                    name="assignedto"
                    placeholder="Assigned to..."
                    stateValue={addNewManualTasks}
                    setState={setAddNewManualTasks}
                  />
                </div>
                <button
                  className={classNames.submitBtn}
                  onClick={handleSubmitManualTasks}
                >
                  Submit
                </button>
                {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
              </div>
            ) : (
              ""
            )}
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default PipelineSubmenu;
